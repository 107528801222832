.formSignature {
  width: 100%;
  display: flex;
  justify-content: center;
  &Label {
    font-size: 16px;
    font-weight: 600;
  }
}
@media screen and (max-width: 700px) {
  :where(.css-dev-only-do-not-override-1frn1vs).ant-form:not(.ant-form-inline)
    .ant-form-item
    .ant-form-item-label:not([class*=" ant-col-xs"]),
  :where(.css-dev-only-do-not-override-1frn1vs).ant-form:not(.ant-form-inline)
    .ant-form-item
    .ant-form-item-control:not([class*=" ant-col-xs"]) {
    flex: none;
  }
  .viewSignature-container {
    margin-top: 15px;
  }
}
.formSignatureDark {
  .formSignatureLabel {
    color: #fff;
  }
}
