$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.agDescription-modal {
  margin-top: 10%;
  margin-left: 25%;
  .ant-modal-content {
    width: 1000px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      background-color: #f8f8fa;
      align-items: center;
      color: #333238;
      display: flex;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        color: #323338;
        font-weight: 600;
      }

      .description {
        margin-bottom: 30px;
      }

      .mondayButtonRed {
        width: 150px;
      }
    }
  }
}

.agDescription-modalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-title {
      color: #fff;
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      color: #fff;
    }

    .ant-modal-body {
      background-color: $cardModalBodyDark;
      color: #fff;

      .title {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1400px) {
  .agDescription-modal {
    margin-left: 10%;
    .ant-modal-content {
      width: 80vw;
    }
  }
}
