$inputModalDark: #12131b;

.EditPlacesInput {
  .ant-form-item {
    margin-bottom: 0px;
  }

  .EditPlacesInputComponent {
    width: 350px !important;
    height: 32px;

    .ant-select-selector {
      background: #f5f5f7 0% 0% no-repeat padding-box !important;
      opacity: 1;
      border-radius: 5px !important;
      border: 0px !important;
      height: 33px !important;

      .ant-select-selection-search-input {
        height: 31px !important;
      }
    }

    &.ant-select-focused .ant-select-selector {
      box-shadow: none !important;
    }
  }
}

.EditPlacesInputDark {
  .EditPlacesInputComponent {
    .ant-select-selector {
      background-color: $inputModalDark !important;
      color: #f2f2f2;
    }

    .ant-select-selection-placeholder {
      color: #acacca;
    }

    .ant-select-selection-search-input {
      background-color: $inputModalDark !important;
      color: #f2f2f2;
    }

    .ant-select-arrow {
      color: #f2f2f2;
    }
  }
}

.ant-select-dropdown {
  &.darkDropDown {
    background-color: $inputModalDark !important;

    .ant-select-item {
      color: #f2f2f2;

      &-option-active,
      &-option-selected {
        background-color: darken($inputModalDark, 5%) !important;
      }
    }
  }
}
