.elevationHtmlConfiguration {
  width: 100%;
  height: 100%;
  .toolbox {
    gap: 10px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    .toolbox-category {
      gap: 10px;
      display: flex;
      flex-direction: column;
      .toolbox-category-tools {
        gap: 5px;
        height: 38px;
        padding: 5px;
        display: flex;
        width: fit-content;
        border-radius: 5px;
        align-items: center;
        background-color: #f5f5f7;
        .indent-input {
          transition: all 0.3s;
          width: 0px !important;
          height: 32px !important;
          border: none !important;
          padding: unset !important;
          min-width: unset !important;
        }
        .indent-input.show {
          width: 60px !important;
          padding: 0.625rem 5px !important;
          border: 1px solid #d9d9d9 !important;
        }
      }
    }
  }
}
