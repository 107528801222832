@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);
@import "../../../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.projectModalContainer {
  $input-color: #ececec !important;
  overflow: visible !important;

  .ant-modal .ant-modal-content {
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    .ant-modal-body {
      background-color: #fff;
    }
  }

  .projectStep {
    width: 100%;
    max-height: calc(100vh - 350px);
    margin: 20px 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .projectStepper {
    height: 36px;
  }

  .ant-select-selector {
    background-color: $input-color;
    border: 0 !important;
    border-radius: 5px !important;
  }

  .ant-input-group {
    background-color: $input-color;
    border-radius: 5px !important;
    overflow: hidden !important;
    .ant-input {
      background-color: $input-color;
      border: 0 !important;
    }
  }

  .ant-input {
    @extend .ant-select-selector;
  }
  .ant-input-group-addon {
    background-color: $input-color;
    border: 0 !important;
  }

  .ant-select-multiple .ant-select-selection-item {
    background: #32c672;
    border-radius: 5px;
    color: #fff;
  }

  .statusCard {
    width: 125px;
    height: 30px;
    border-radius: 5px;
    opacity: 1;
    background-color: unset;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    letter-spacing: 0;
    text-transform: uppercase;
    padding: 0 10px;
  }
  .footerButtonsProject {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .forecastingFooter {
      justify-content: unset;
    }
    button {
      width: 150px;
    }
  }
}

.projectModalContainerDark {
  .ant-modal .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;

      .antCustomStepper {
        border-radius: 10px;
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark;
          }
          .ant-steps-item-wait {
            background-color: $cardModalHeaderDark;
            &::after {
              border-left-color: $cardModalHeaderDark;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .mainGrid {
        .labeledInputLabel {
          color: #fff;
        }

        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
      .dobStepContainer {
        .stepHeaderContainer {
          .stepTitle,
          .stepSubtitle {
            color: #fff;
          }
        }
        .controlPanel {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
        }
        .violationsPanel {
          .violationInfoText {
            .activeViolations,
            .totalViolations {
              color: #fff;
            }
          }
        }
      }
      .subcontractorsStepContainer {
        .inputsContainer {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
        }
      }
      .geoFencStepContainer {
        .geofencestep-card-container {
          background-color: $cardModalHeaderDark;
          .geofencestep-card-header-container {
            background-color: $cardModalHeaderDark;
            color: #fff;
          }
        }
      }
    }
  }
  .ant-input-wrapper {
    background-color: $inputModalDark !important;
    .ant-input-group-addon {
      background-color: $inputModalDark !important;
      color: #acacca;
    }
  }
}

.agGridContainerScroll {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .solidGreyGridTheme {
    .ag-theme-alpine {
      --ag-row-border-color: transparent;
      --ag-row-hover-color: null;
      --ag-checkbox-checked-color: #2661ad;
      --ag-range-selection-border-color: transparent;
      --ag-range-selection-background-color: #03305633;
      --ag-header-height: 100px;
      --ag-background-color: #f5f5f7;
    }

    .ag-body-viewport {
      height: calc(8 * 40px) !important;
    }

    .ag-header {
      border: 0 !important;
    }

    .ag-header-cell-text {
      @include text(16px, 600, 0.21px, #383554);
    }
    .ag-header-cell {
      background-color: #fff;
    }
    .ag-root-wrapper {
      border: 0;
    }
    .ag-menu-option-active {
      background-color: #2661ad;
      color: white;
    }
    .ag-row .ag-cell {
      // border-right: 5px solid #fff !important;
      // border-bottom: 5px solid #fff !important;
      overflow: hidden;
    }

    .ag-pivot-mode-panel {
      height: fit-content;
      padding: 5px;
    }
    .ag-column-select-header {
      height: fit-content;
      padding: 5px;
    }
    .ag-row-selected {
      background-color: #d1eaff94 !important;
    }
    .ag-selection-checkbox {
      margin-right: 12px;
    }
  }
}

// @media (max-width: 1050px) {
//   .projectModalContainer {
//     .overViewStepContainer .mainGrid {
//       margin-top: 10px !important;
//       width: 100%;
//       grid-template-columns: 47% 47% !important;
//       column-gap: 2rem !important;
//       .inputsGrid {
//         grid-template-columns: [full-start] 45% 45% [full-end] !important;
//         .fullInput {
//           min-width: 100% !important;
//         }
//       }
//     }
//   }
// }

@media (max-width: 920px) {
  .projectModalContainer {
    .dobStepContainer .controlPanel {
      height: fit-content;
      display: flex;
      flex-direction: column;
      .inputsContainer {
        margin-right: 0px;
      }
      .buttonsContainer {
        display: flex;
        flex-wrap: wrap;
        .mondayButtonContainer {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .ant-modal-wrap.fullScreenModal.projectModalContainer {
    .subcontractorsStepContainer .inputsContainer {
      display: grid;
      grid-template-columns: 100% !important;
    }
    .dobStepContainer .violationsPanel {
      display: grid !important;
      grid-template-columns: 100% !important;
    }
    .ant-steps-vertical {
      height: fit-content;
      gap: 5px;
      .ant-steps-item-container {
        height: 36px;
        .ant-steps-item-content {
          display: flex;
          align-items: center;
        }
      }
    }
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-finish:after {
      transform: rotate(0deg);
      left: 100%;
      top: -36px;
    }
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-active:after {
      transform: rotate(0deg);
      left: 100%;
      top: -36px;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item:last-child:after {
      display: none;
    }
    .footerContainer {
      position: relative;
      bottom: -15% !important;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }
    .footerButtonsProject {
      button {
        width: 100px;
      }
    }

    .stepHeaderContainer {
      margin-bottom: 90px !important;
    }
    .overViewStepContainer .inputsGrid {
      display: block !important;
    }
    .ant-modal {
      top: 0px !important;
      left: 0px !important;
      right: 0px !important;
      height: 100% !important;
    }
    // .ant-modal-content {
    //   height: 100vh !important;
    //   overflow: auto;
    // }
    .form.ant-form.ant-form-horizontal {
      overflow: auto !important;
      height: 100% !important;
    }
    .mainGrid {
      display: block !important;
    }
  }
}
