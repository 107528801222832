@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.new-template-modal {
  width: fit-content !important;
  height: fit-content !important;
  .ant-modal-content {
    .ant-modal-close .ant-modal-close-x {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 17px !important;
        height: 17px !important;
        path {
          fill: #323338 !important;
        }
      }
    }
    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      background: #f4f5f6;
      border-bottom: none;
      height: 50px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 150px);
      width: calc(100vw - 100px);
      padding: 20px !important;
      gap: 20px;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      .edit_template__header {
        display: flex;
        justify-content: space-between;
      }
      .designer-proposal {
        flex: 1;
        gap: 20px;
        width: 100%;
        display: flex;
        overflow-y: hidden;
      }
      .previewContainer {
        // width: 100%;
        width: 1000px;
        display: flex;
        align-self: center;
        justify-content: center;
      }
    }
    // .ant-modal-footer {
    //   border-radius: 0 0 10px 10px;
    //   border-top: none;
    // }
  }
}

.new-template-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
      path {
        fill: #fff !important;
      }
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .tox {
        border: none;
        border-radius: 10px;
        .tox-menubar {
          background-color: $cardModalHeaderDark;
          .tox-mbtn__select-label {
            color: #fff;
          }
        }
        .tox-toolbar-overlord {
          background-color: $cardModalHeaderDark !important;
          .tox-toolbar__primary {
            background-color: $cardModalHeaderDark !important;
          }
          .tox-toolbar__group {
            background-color: $cardModalHeaderDark !important;
            border: none;
            .tox-split-button:hover {
              border: none;
            }
            .tox-tbtn {
              svg {
                fill: #fff;
              }
              &:focus {
                background-color: $cardModalBodyDark;
              }
              &:hover {
                background-color: $cardModalBodyDark;
              }
            }
            .tox-tbtn__select-label {
              color: #fff !important;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $cardModalBodyDark;
    }
  }
}

.jodit-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure it is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.jodit-dialog__content > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.jodit-dialog-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  // max-width: 80%;
  width: 100%;
  height: 850px;
  // overflow-y: auto;
  // max-height: 80%;
  overflow: auto;
}

.jodit-dialog-content textarea {
  width: 100%;
  height: 90%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
}
