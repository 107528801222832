@use "/src/index.scss" as index;

.employee-info-window {
  border-radius: 5px;
  overflow: hidden;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  .mondayButtonRedHover:hover {
    @include index.mondayButton(#fe4c4a, #eb4345);
  }
  .info-header {
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f5f6;
    padding: 0px 10px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    .Completed {
      color: #7ac14d;
    }
    .Draft {
      color: #787677;
    }
    .Pending {
      color: #ecde7c;
    }
    .info-title {
      color: #231f20;
    }
    svg {
      fill: #231f20;
      cursor: pointer;
    }
  }
  .info-body {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    padding: 10px;
    font-weight: 400;
    .location-information {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 5px;
      svg {
        cursor: pointer;
      }
    }
    .info-data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .employee-name {
        color: index.$blue;
        cursor: pointer;
      }
      .cancel-info {
        cursor: pointer;
        &:hover {
          color: index.$red;
        }
      }
      .employee-status {
        display: flex;
        align-items: center;
        gap: 5px;
        .status-pin {
          height: 8px;
          width: 8px;
          border-radius: 50%;
        }
      }
      label {
        font-weight: 600;
      }
    }
  }
  .approve-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .distance-data {
    display: flex;
    align-items: center;
    gap: 5px;
    .duration {
      color: #acacac;
    }
  }
  .labeledInputContainer {
    margin-top: 10px;
    .labeledInputLabel {
      font-size: 14px;
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
  .show-route {
    cursor: pointer;
    color: index.$blue;
    font-weight: 600;
  }
}

.employee-info-window-dark {
  background-color: index.$mainDarkBackgroundColor;
  color: #fff;
  .info-header {
    background-color: index.$cardDarkBackgroundColor;
    .info-title {
      color: #fff;
    }
    svg {
      fill: #fff !important;
    }
  }
  .labeledInputContainer {
    .ant-form-item {
      .ant-select .ant-select-selector {
        .ant-select-clear svg {
          fill: #acacca !important;
        }
      }
    }
  }
}
