body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans";
  margin-top: 0;
  margin-bottom: 0;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gisha-Normal";
  src: local("Gisha-Normal"), url("./fonts/gisha-normal.ttf") format("truetype");
  font-family: "Gisha-Bold";
  src: local("Gisha-Bold"), url("./fonts/gisha_760.ttf") format("truetype");
}

.form-field,
input {
  border: 1px solid #d9d9d9;
  padding: 0.625rem 0.75rem;
  border-radius: 0.375rem;
  line-height: 1.15;
  font-size: 14px !important;
}

.ant-notification {
  position: absolute;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.ant-notification-notice-icon {
  display: none;
}

/* .ant-notification:hover {
  transform: scale(1.01);
  transition: 0.1s;
} */

.info__notification__dark:hover,.info__notification:hover,.delete__notification:hover, .delete__notification__dark:hover, .undo_notification:hover {
  transform: scale(1.01);
  transition: 0.1s;
}

.info__notification {
  background-image: linear-gradient(90deg, #138b9e 2%, #fff 2%) !important;
  cursor: pointer;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.info__notification__dark{
  background-image: linear-gradient(90deg, #138b9e 2%, #12131b 2%) !important;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #138c9e52;

  .ant-notification-notice-message{
    margin-left: 0 !important;
    color: #138b9e !important;
    font-weight: 600;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }
  .ant-notification-notice-description{
    p{
      color: white;
    }
  }
  .ant-notification-notice-close{
    path{
      fill: #9a9a9a; 
    }
  }
}

.info__notification .ant-notification-notice-message {
  margin-left: 0 !important;
  color: #138b9e !important;
  font-weight: 600;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}


.delete__notification {
  background-image: linear-gradient(90deg, #fd5151 2%, #fff 2%) !important;

}

.delete__notification__dark{
  background-image: linear-gradient(90deg, #fd5151 2%, #12131b 2%) !important;
  cursor: pointer;

  box-shadow: 0px 0px 10px 0px #fd515152;

  .ant-notification-notice-message{
    margin-left: 0 !important;
    color: #fd5151 !important;
    font-weight: 600;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }
  .ant-notification-notice-description{
    p{
      color: white;
    }
  }
  .ant-notification-notice-close{
    path{
      fill: #9a9a9a; 
    }
  }
}

.delete__notification .ant-notification-notice-message {
  margin-left: 0 !important;
  color: #fd5151 !important;
  font-weight: 600;
}

/* .ant-notification-notice-wrapper {
  background-color: #202124 !important; 
  color: white !important;
} */

.undo_notification {
  border-radius: 5px;
  /* margin-left: 300px !important; */
  background-color: #202124 !important;
  width: 100% !important;
  height: 50px !important;
  bottom: 30px !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-content: space-between !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  color: white !important;
}

.undo_notification
  .ant-notification-notice-content
  .ant-notification-notice-with-icon
  .ant-notification-notice-description
  div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.undo_notification .ant-notification-notice-message {
  line-height: 1 !important;
  margin-left: 300px !important;
  font: normal normal 400 22px/39px Open Sans !important;
  color: white !important;
  margin-top: 0px !important;
}

.undo_notification .undo {
  font: Open Sans 16px !important;
  font-weight: 600 !important;
  margin-right: 30px !important;
  padding-left: 10px !important;
  cursor: pointer;
  color: #4285f4 !important;
}
.undo_notification .ant-notification-notice-close {
  top: 16px !important;
  color: white !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-content: space-between !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}

.ant-notification-notice-description {
  margin: 0 !important;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
.ant-notification-notice-message {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
