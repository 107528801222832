@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newViolationModalContainer {
  .ant-radio-button-wrapper {
    font-weight: 400;
  }
  .infoBox {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    padding: 10px;
    border-radius: 4px;
    color: #0050b3;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 5px;

    a {
      color: #0050b3;
      text-decoration: underline;
    }
  }

  .ant-modal-content {
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  }
  .borderedTextCard {
    padding: 0;
    padding-top: 15px;
    border-radius: 0;
    border: none;
    border-top: 1px solid black;
    align-items: center;
    .childrenContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
    }
    .cardTitle {
      color: black;
      font-size: 16px;
    }
  }

  .borderedTextCard.amount {
    .childrenContainer {
      display: flex;
      gap: 2%;
      .labeledInputContainer {
        width: 49%;
      }
    }
  }

  .borderedTextCard.generalInformation {
    margin-top: 15px;
    .childrenContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .borderedTextCard.additionalInformation {
    .childrenContainer {
      // gap: 2%;
      align-items: center;
    }
  }

  .inputContainer {
    @include flex(baseline, flex-start);
    flex-wrap: wrap;
    width: 1200px;
    max-height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;

    .amount-information-container {
      display: grid;
      grid-template-columns: [full-start] 49% 49% [full-end];
      gap: 2%;
    }
    .syncIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -7px;
      height: 32px;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      border-radius: 5px;
      background-color: #71cf48;
      // transform: translateY(2rem);
      cursor: pointer;
    }

    .downloadViolationButton {
      svg {
        fill: #fff !important;
      }
    }

    .labeledInputLabel {
      clear: both;
      // display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .nestedInputContainer {
    @include flex(flex-start, flex-start, column);
    width: 50%;
  }
  .ant-modal-body {
    height: fit-content;
    width: fit-content;
    padding: 40px;
    padding-bottom: 62px;
    background-color: #fff;
  }

  .labeledInputContainer {
    // padding-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }

  .labeledInputContainer:last-child {
    padding-right: 0;
  }

  .rowFlex {
    @include flex(baseline, flex-start);
    min-width: 100%;
  }

  .detailsContainer {
    min-width: 625px;
  }

  .ant-picker {
    width: 100%;
    font-weight: 400;
  }

  .ant-input {
    font-weight: 400;
  }

  .ant-select-selector {
    font-weight: 400;
  }

  .newFooterContainer button {
    width: 150px;
  }
}

.document-modal-container {
  width: fit-content !important;
  height: fit-content !important;
  .ant-modal-content {
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    height: fit-content;
    width: fit-content;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      max-height: calc(100vh - 60px);
      height: 800px;
      width: 650px;
      max-width: 98vw;
    }
  }
}

.document-modal-container.document-dark {
  .ant-modal-content {
    background-color: #1f212d;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #fff;
      }
    }
  }
}

.newViolationModalContainerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: #1f212d;
      .borderedTextCard .childrenContainer {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-disabled {
          color: #acacca !important;
        }
        .ant-input-wrapper {
          background-color: $inputModalDark !important;
          .ant-input-group-addon {
            background-color: $inputModalDark !important;
            color: #fff;
            svg {
              fill: #fff;
            }
          }
        }
        .ant-picker {
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        .ant-picker-disabled {
          .ant-picker-input input {
            color: #acacca !important;
          }
        }
        .ant-btn {
          border: none;
        }
        .ant-checkbox-wrapper {
          span {
            color: #fff;
          }
        }
        .ant-radio-wrapper {
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .newViolationModalContainer {
    .ant-modal-body {
      width: 100%;
    }
    .inputContainer {
      width: 100%;
      .detailsContainer {
        min-width: 100%;
      }
    }
  }
  .borderedTextCard {
    padding-right: 10px !important;
  }
}

@media (max-width: 900px) {
  .amount-information-container {
    display: flex !important;
    flex-direction: column !important;
    // gap: 2%;
  }
}
@media (max-width: 810px) {
  .newViolationModalContainer {
    padding: 0px !important;
  }
}

@media (max-width: 450px) {
  .newViolationModalContainer {
    form.ant-form.ant-form-horizontal {
      width: 100% !important;
    }
  }
  .newViolationModalContainer .ant-modal-body {
    width: 100% !important;
  }
  .newViolationModalContainer .detailsContainer {
    min-width: 100% !important;
  }
}
