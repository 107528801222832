@import "/src/index.scss";

.mappingConfigModal {
  .mappingModalWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;

    .mappingModalLabel {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 5px;
    }

    .quill {
      margin-bottom: 20px;
      width: 100%;
      .ql-toolbar {
        background-color: #f5f5f7;
        border-radius: 5px;
        margin-bottom: 5px;
        border: none;
      }
      .ql-container {
        min-height: 80px;
        max-height: 120px;
        border-radius: 5px;
        border: none;
        background-color: #f5f5f7;
        overflow-y: auto;

        .ql-editor {
          width: 100%;

          p {
            color: #323338;
          }
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .keyInputs {
      max-height: 210px;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
    }

    .footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .mappingModalFooter {
    display: flex;
    justify-content: space-between;
  }
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f8fa;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 22px;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: $textColor;
      }
    }
    .ant-modal-body {
      margin: 0;

      span {
        font-size: 16px;
      }
      .ant-input {
        height: 32px;
      }
    }
  }
  &.noPadding {
    .ant-modal-content {
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.mappingConfigModalDark {
  .ant-modal-content {
    border-radius: 10px;
    background: #12131b;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: white;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #12131b;
      border-radius: 10px 10px 0px 0px;

      .ant-modal-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 22px;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: white;
      }
    }
    .ant-modal-body {
      margin: 0;
      background-color: #1f212d;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      span {
        font-size: 16px;
      }
      .ant-input {
        height: 32px;
      }
    }
  }
}
