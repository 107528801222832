.mobileStatusPanelContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .mobileStatusPanel {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 1px 4px 0px #00000026;
    border-radius: 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease,
      padding 0.3s ease;

    &.dark {
      background-color: #12131b;
    }

    .mobileStatusContent {
      border-radius: 5px;
      color: white;
      min-width: 94px;
      width: 100%;
      max-height: 94px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease,
        color 0.3s ease;

      .count {
        font-size: 55px;
        line-height: 65px;
        // line-height: 75px;
      }
      .text {
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        transition: line-height 0.3s ease, color 0.3s ease;
      }
    }
  }
}

.mobileFiltersPanelWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .mobileFiltersPanel {
    padding: 10px;
    background-color: white;
    box-shadow: 0px 1px 4px 0px #00000026;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    overflow-y: auto;

    &.dark {
      background-color: #12131b;
      color: #f4f5f6;
    }
  }
  .details {
    padding: 10px;
    background-color: white;
    box-shadow: 0px 1px 4px 0px #00000026;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.dark {
      background-color: #12131b;
    }
    .ant-input-affix-wrapper {
      margin: 0;
    }

    .searchInput {
      width: 200px;
      height: 44px;
      background: #f4f5f6;
      &.dark {
        background-color: #2a2b3a;
        color: #f4f5f6;
        input::placeholder {
          color: #f4f5f6;
        }
      }
    }

    &.dark {
      background-color: #12131b;
      color: white;
    }
    .paginationControls {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 2px;
      color: #1264a3;
      font-weight: 600;
      align-items: end;
      .tag {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 23px;
        font-weight: 400;
        color: white;
        border-radius: 2px;
        padding-left: 5px;
        padding-right: 5px;
        .tagValue {
          width: 102px;
          white-space: nowrap;
          overflow: auto;
        }
        .x {
          font-size: 16px;
          margin-left: 5px;
        }
      }
      &.loading {
        cursor: not-allowed;
        color: lightgray;
      }
    }
  }
}

.mobileBasePageGrid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mobileBasePageGridWrapper {
  box-shadow: 0px 1px 4px 0px #00000026;
  background-color: white;
  border-radius: 10px;

  &.dark {
    background-color: #2a2b3a;
  }

  .categoryHeader {
    height: 45px;
    border-radius: 10px 10px 0px 0px;
    background: #f4f5f6;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    align-items: center;
    &.dark {
      background-color: #12131b;
      color: #f4f5f6;
    }
  }
}

.mobileBasePageGridContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 0;
  gap: 10px;
  overflow: auto;
  border-radius: 10px 10px 0px 0px;
  .empty {
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    gap: 10px;
    background-color: #f4f5f6;
    padding: 10px;
    &:last-child {
      margin-bottom: 10px;
    }
    .elements {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .header {
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
      }
      .field {
        white-space: nowrap;
        overflow: auto;
        max-width: 100%;
      }
    }

    &.dark {
      background-color: #12131b;
      color: #f4f5f6;
    }
  }
}
