@import "../../../../../../../../index.scss";

.docFileTreeHeader {
  width: 100%;
  @include flex(center, space-between);

  .gDriveIcon {
    width: 40px;
    height: 36px;
    margin-right: 25px;
  }
  .docFileTreeHeaderTitle {
    @include text(16px, 600, 0, #323338);
  }
}
