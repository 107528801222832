$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.safetyDynamicFormsWrap {
  overflow: hidden;
}

.safetyDynamicForms {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100dvh !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  &::before {
    display: none !important;
  }
  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    .ant-modal-header {
      border-radius: 0 !important;
      background-color: #f5f5f7;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;

        .titleContainer .buttons .settingsIcon {
          height: 22px;
          width: 22px;

          cursor: pointer;
        }
      }
    }

    .ant-modal-close {
      height: 45px;
      aspect-ratio: 1;
    }

    .ant-modal-body {
      height: calc(100vh - 119px);
      width: 100%;
      padding: 10px 10px 0 10px !important;
      padding: 0;
      .stepper-section {
        margin-bottom: 1rem;
      }

      .stepper-section-mobile {
        .ant-form-item {
          margin-bottom: 0;
        }
      }

      .ant-form {
        overflow-x: hidden;
        max-height: calc(100vh - 231px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row-reverse;
        flex-direction: row-reverse;
        overflow-y: auto;
        scroll-behavior: smooth;
        height: inherit;
        .uploaderSection {
          position: sticky !important;
          width: 25vw;
          top: 0;
          z-index: 100;
          height: 100%;
          background-color: white;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .uploaderButton {
            cursor: pointer !important;
          }

          .safetyFilesContainer {
            height: auto;
            max-height: 300px;
            overflow-y: auto;
            .safetyFilesTitle {
              display: flex;
              .safetyFilesTitleLine {
                border-top: 1px solid #000;
                margin: 10px 0;
                flex: 1;
              }
              .safetyFilesTitleText {
                margin: 0 10px;
                font-weight: 600;
              }
            }
          }
          .safetyFilesContainer.hideName {
            .fileAuthor {
              display: none !important;
            }
          }
        }
        .form-section {
          width: 100%;
          height: 98%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          overflow-x: hidden;
        }
        .signings {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          gap: 1rem;
        }
        .signContainer {
          width: 240px;
          height: 150px;
          position: relative;
          overflow: hidden;
          background-color: #fff;
          img {
            object-fit: contain;
            width: 100%;
            height: calc((70vw - 250px) / 3);
            position: absolute;
            top: calc((70vw - 250px) / -10);
            @media (max-width: 1600px) {
              height: calc((70vw - 200px) / 3);
              position: absolute;
              top: calc((70vw - 250px) / -12);
            }
          }
        }
        .borderedTextCard {
          margin-top: 1rem;
          padding: 15px 0px 0px 0px;
          border-radius: 0;
          border: none;
          border-top: 1px solid black;
          align-items: center;
          .cardTitle {
            font-size: 16px;
            color: black;
            z-index: unset !important;
          }
          .childrenContainer {
            display: flex;
            gap: 1.2rem;
            .multipleSelect {
              max-width: 40%;
            }
            .labeledInputContainer {
              // .tox-tinymce {
              //   border: none;
              //   border-radius: 5px;
              //   .tox-menubar {
              //     display: none;
              //   }
              //   .tox-statusbar {
              //     display: none;
              //   }
              //   .tox-editor-container {
              //     max-height: 145px;
              //   }
              //   .tox-toolbar__primary {
              //     background-color: #f5f5f7;
              //     display: flex;
              //     justify-content: flex-start;
              //     width: 100%;
              //     .tox-toolbar__group {
              //       border: none;
              //     }
              //   }
              //   .tox-sidebar-wrap {
              //     border-radius: 5px;
              //     .tox-edit-area {
              //       border-radius: 5px;
              //       .tox-edit-area__iframe {
              //         margin-top: 10px;
              //         background: #f5f5f7;
              //         border-radius: 5px;
              //         border: none;
              //       }
              //     }
              //   }
              // }
              .ant-form-item-control-input-content {
                height: unset;
              }
              .tox-tinymce {
                min-height: 250px !important;
                // height: 140px !important;
                .tox-edit-area {
                  min-height: 200px;
                  height: auto;
                }
              }
              .richTextComp .tox {
                .tox-editor-container {
                  .tox-editor-header {
                    background-color: #f8f8fa;
                    border-radius: 5px;
                    .tox-toolbar-overlord {
                      background-color: #f8f8fa;
                      border-radius: 5px;
                      .tox-toolbar__primary {
                        display: flex;
                        gap: 10px;
                        background-color: #f8f8fa;
                        border-radius: 5px;
                        max-width: 600px;
                        overflow-x: auto;
                        .tox-toolbar__group {
                          display: flex;
                          gap: 10px;
                          .tox-tbtn {
                            max-width: 20px;
                          }
                        }
                      }
                      //.labeledInputLabel {
                      //}
                      .ant-form-item {
                        .ant-row {
                          .ant-col {
                            .ant-form-item-control-input {
                              .ant-form-item-control-input-content {
                                .ant-picker {
                                  width: 100%;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .tox-sidebar-wrap {
                  margin-top: 5px;
                  .tox-edit-area {
                    .tox-edit-area__iframe {
                      border: none;
                      background-color: #f8f8fa;
                    }
                  }
                }
              }
            }
          }
        }
        .safetyVisualization {
          .childrenContainer {
            width: 100%;
          }
        }
        .notesCard {
          .childrenContainer {
            .ant-form-item {
              width: 100%;
              .ant-row {
                width: 100%;
                .ant-col {
                  width: 100%;
                  .ant-form-item-control-input {
                    width: 100%;
                    .ant-form-item-control-input-content {
                      width: 100%;
                      .all-comments-container {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row-reverse;
                        width: 100%;
                        justify-content: space-between;
                        // max-height: 450px;
                        // overflow-y: auto;
                        .comments-left-side {
                          height: fit-content;
                          width: 60%;
                        }
                        .ant-divider {
                          max-height: 40px;
                        }
                        .current-comments {
                          margin-top: 50px;
                          width: 39%;
                          overflow-y: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 10px;
      // button {
      // width: 150px;
      // }
      .buttonContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .safety-notes-button {
          border-radius: 5px;
          background-color: #1264a3;
          // color: white !important;
          padding: 5px 5px 0 5px;
          span {
            color: white !important;
            align-items: center;
          }
        }
      }
      .previewButton {
        width: 36px !important;
        .mondayButtonText,
        .mondayButtonDivider {
          display: none !important;
        }
      }
    }
  }
}

.safetyEmailBox {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;

  .EmailBoxContainer {
    height: 600px;
    z-index: 99999;
    position: absolute;
    bottom: 0;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    .ant-form-item {
      width: 100%;
      margin-bottom: 0;
    }
    .ant-form {
      display: flex !important;
      justify-content: flex-start !important;
      flex-direction: column !important;
      height: 100% !important;

      .subject-container {
        width: 100%;
      }
      .emailBoxContainer {
        // height: 100% !important;
        .tox-tinymce {
          border: none;
          border-radius: 5px;
          .tox-menubar {
            display: none;
          }
          .tox-statusbar {
            display: none;
          }
          .tox-toolbar.tox-toolbar--scrolling:nth-child(n + 1) {
            display: none;
          }
          .tox-toolbar__primary {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            .tox-toolbar__group {
              border: none;
            }
          }
          .tox-sidebar-wrap {
            border-radius: 5px;
            .tox-edit-area {
              border-radius: 5px;
              .tox-edit-area__iframe {
                margin-top: 10px;
                border-radius: 5px;
                border: none;
              }
            }
          }
        }
      }
    }
    .ant-upload-wrapper.uploadDocumentsClass
      .ant-upload-list
      .ant-upload-list-item
      .ant-upload-list-item-name {
      width: 200px;
      overflow: hidden;
    }
  }
}

.safetyDynamicFormsWrapDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;

        .titleContainer .buttons .settingsIcon path {
          fill: #fff;
        }
      }
    }
    .ant-modal-body {
      .stepper-section {
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark;
          }
          .ant-steps-item-wait {
            background-color: $cardModalHeaderDark;
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .uploaderSection {
        background-color: $cardModalBodyDark !important;
        .safetyFilesTitleText {
          color: #fff;
        }
      }
      .form-section {
        background-color: $cardModalBodyDark;
        .childrenContainer {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-disabled {
            color: #acacca;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
              .ant-select-selection-overflow-item .ant-select-selection-item {
                background-color: #323338;
                .ant-select-selection-item-remove svg {
                  fill: #fff;
                }
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
          .react-tel-input {
            .form-control {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .flag-dropdown {
              background-color: $inputModalDark;
              border-radius: 5px 0px 0px 5px;
              .selected-flag {
                &:hover {
                  background-color: $inputModalDark;
                }
                &:focus {
                  background-color: $inputModalDark;
                }
              }
              .arrow {
                border-top: 4px solid #fff;
              }
            }
            .open {
              background-color: $inputModalDark;
              border-radius: 5px 0px 0px 5px;
              .selected-flag {
                background-color: $inputModalDark;
                .arrow {
                  border-bottom: 4px solid #fff;
                  border-top: 4px solid transparent;
                }
              }
            }
          }
          .ant-picker {
            background-color: $inputModalDark !important;
            .ant-picker-input {
              background-color: $inputModalDark;
              input {
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-picker-suffix svg {
                color: #acacca;
              }
              .ant-picker-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
            }
          }
          .ant-picker-disabled {
            .ant-picker-input input {
              color: #acacca !important;
            }
          }
          .quill {
            background-color: $cardModalBodyDark;
            .ql-toolbar {
              background-color: #15161f;
              .ql-formats {
                color: #fff !important;
                .ql-picker-label {
                  color: #fff;
                  &::before {
                    color: #fff;
                  }
                  .ql-stroke {
                    stroke: #fff;
                  }
                }
                button .ql-stroke {
                  stroke: #fff;
                }
              }
            }
            .ql-container {
              background-color: $inputModalDark;
              .ql-editor {
                &::before {
                  color: #acacca;
                }
                p {
                  color: #fff !important;
                }
              }
            }
          }
          .tox {
            border: none;
            .tox-menubar {
              background-color: $cardModalHeaderDark;
              border-radius: 5px 5px 0px 0px;
              .tox-mbtn__select-label {
                color: #fff;
              }
            }
            .tox-mbtn--select:hover {
              background-color: $cardModalBodyDark;
            }
            .tox-toolbar-overlord {
              background-color: $cardModalHeaderDark !important;
              .tox-toolbar__primary {
                background-color: $cardModalHeaderDark !important;
              }
              .tox-toolbar__group {
                background-color: $cardModalHeaderDark !important;
                border: none;
                .tox-split-button:hover {
                  border: none;
                }
                .tox-tbtn {
                  svg {
                    fill: #fff;
                  }
                  &:focus {
                    background-color: $cardModalBodyDark;
                  }
                  &:hover {
                    background-color: $cardModalBodyDark;
                  }
                }
                .tox-tbtn__select-label {
                  color: #fff !important;
                }
              }
            }
          }
          .ant-radio-wrapper span {
            color: #fff;
          }
          .ant-checkbox-wrapper span {
            color: #fff;
          }
          .ant-btn-dangerous:disabled,
          .ant-btn-primary:disabled {
            border: 1px solid #acacca;
            color: #acacca;
          }

          .ant-form-item {
            .ant-row {
              .ant-col {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    .labeled-qb-input {
                      background-color: $inputModalDark !important;

                      .qb-input {
                        background-color: $inputModalDark !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.task-from-safety-modal {
  visibility: hidden;
}

@media (max-width: 1525px) {
  .safetyDynamicForms {
    .ant-modal-body {
      height: calc(100vh - 140px) !important;
      .safetyDynamicForms {
        .borderedTextCard {
          padding-right: 0 !important;
          max-width: 100% !important;
          .childrenContainer {
            max-width: 100% !important;
          }
        }
      }
    }

    .ant-modal-footer {
      flex-direction: column !important;
      gap: 10px !important;
      padding-bottom: 20px !important;
      button {
        width: auto !important;
      }
    }
  }
}

@media (max-width: 1370px) {
  .safetyDynamicForms {
    &::before {
      height: 0 !important;
    }
    .ant-modal-content {
      .ant-modal-body {
        .ant-form {
          display: flex;
          align-items: flex-start;
          flex-direction: column !important;
          overflow-y: auto !important;
          .uploaderSection {
            display: flex;
            justify-content: center;
            width: 100% !important;
            padding-top: 1rem;
            min-width: 300px;
            height: max-content !important;
            .anticon-file-text {
              display: none;
            }
          }
          .form-section {
            height: 80vh;
            overflow-y: auto;
          }
        }
      }
    }
    .ant-modal-footer {
      button {
        width: 140px !important;
      }
    }
  }
}

@media (min-width: 1200px) {
  .safetyDynamicForms {
    .ant-modal-content {
      .ant-modal-body {
        .form-section {
          .uploaderSection {
            position: sticky !important;
            top: 0;
            z-index: 100;
            background-color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .safetyDynamicForms {
    height: calc(100dvh - 50px) !important;
    .ant-modal-content {
      height: calc(100dvh - 50px) !important;
      .notesCard {
        .all-comments-container {
          flex-direction: column !important;
          .comments-left-side {
            width: 100% !important;
          }
          .current-comments {
            width: 100% !important;
          }
        }
      }
    }
  }
  .safetyEmailBox {
    display: flex !important;
    justify-content: flex-start !important;
  }
}
@media (max-width: 700px) {
  .safetyDynamicForms {
    .ant-modal-content {
      .ant-modal-body {
        .ant-form {
          .uploaderSection {
            margin-top: 0.5rem;
            .uploaderTitle {
              display: none;
            }
          }
          .borderedTextCard {
            margin-left: 0 !important;
            min-width: unset !important;
            width: 100%;
            .cardTitle {
              position: relative !important;
            }
            .multipleSelect {
              max-width: unset !important;
            }
            .childrenContainer {
              // align-items: center;
              justify-content: center;
            }
            .childrenContainer .labeledInputContainer {
              width: 100% !important;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 690px) {
  .safetyDynamicForms {
    .ant-modal-body {
      height: calc(100vh - 140px) !important;
      .borderedTextCard {
        padding-right: 0 !important;
        max-width: 100% !important;
        .childrenContainer {
          max-width: 100% !important;
        }
      }
    }
    .ant-modal-footer {
      flex-direction: column !important;
      gap: 10px !important;
      padding-bottom: 20px !important;
      button {
        width: auto !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .safetyDynamicForms {
    .ant-modal-content {
      .form-section {
        max-height: calc(100vh - 335px) !important;
        height: calc(100vh - 335px) !important;
      }
      .ant-form {
        // max-height: calc(100vh - 313px) !important;
        // height: calc(100vh - 313px) !important;
        // transform: translateY(2rem) !important;
        .uploaderSection {
          position: fixed !important;
          top: unset !important;
          bottom: 0 !important;
          position: fixed !important;
          top: unset !important;
          bottom: 0 !important;
          svg {
            // height: 3rem;
            aspect-ratio: 1;
          }

          .fileCard {
            height: 3rem;

            .uploadedFile {
              height: 1rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1030px) {
  .safetyDynamicForms {
    .ant-modal-footer {
      .mondayButtonText {
        display: none !important;
      }
      .mondayButtonDivider {
        display: none !important;
      }
      button {
        width: auto !important;
      }
    }
  }
}

.safetySmallInfoModal {
  .ant-modal-body {
    padding: 20px !important;
    .safetySmallInfoModalContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .safetySmallInfoModalLink {
        color: white;
        min-width: 150px;
        max-width: 330px;
        height: 32px;
        background: #1264a3;
        border-radius: 5px;
        text-align: center;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        line-height: 2;
      }
    }
  }
}

.changeBackdrop {
  animation-duration: 0 !important;
  // .maskLoadingDiamonds {
  //   backdrop-filter: unset !important;
  //   // z-index: 99990 !important;
  // }
}
