.ForecastingStepContainer {
  overflow: auto;
  flex-direction: column;
  gap: 10px;
  .forcastingPage {
    .forecastingContent {
      height: auto;
      .workTypesCards {
        overflow: auto;
      }
      .forecastingCollapses {
        height: auto;
      }
    }
  }
}
