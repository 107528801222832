@import "../../../../../index.scss";

.controllerPage {
  @include flex(center, space-between);
  padding: 22px;
  padding-top: 0;
  position: relative;
  height: fit-content;
  max-width: 100vw;
  opacity: 1;

  .controller {
    width: 100%;
    min-height: 520px;
    height: fit-content;
    @include flex(center, space-between, column);

    .columnsContainer {
      width: 100%;
      display: flex;
      gap: 24px;
      align-items: start;

      .columnsCheckboxPanel {
        width: 100%;
        padding: 12px;
        background-color: #2a3550;
        border-radius: 2px;
        max-height: 480px;
        overflow-y: auto;

        .ant-checkbox-wrapper {
          padding: 5px 0;
          color: #fff;
          text-transform: capitalize;
          font-size: 12px;
          letter-spacing: 0.5px;
        }

        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
        }

        .label {
          font-size: 14px;
          color: white;
          margin-bottom: 4px;
        }
      }
    }

    .controlsContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .pinnedRowsSelect {
        padding: 0;
        margin: 12px 0;
        width: 200px;
        border-radius: 8px;
        height: 32px;
      }
    }

    .actionButtonsContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      margin-top: 12px;
      gap: 12px;
    }

    .liveButton {
      text-transform: uppercase;
      background-color: #1f2a44 !important;
      color: white !important;
      letter-spacing: 1.6px;
      border-radius: 3px !important;

      &:hover {
        background-color: white !important;
        color: #1f2a44 !important;
        border-color: #1f2a44 !important;
      }
    }

    .lastUpdatedButton {
      margin-top: 3px;
      height: 40px !important;
      width: fit-content;
      min-width: 188px;
      font-size: 14px;
      text-transform: capitalize;
      background-color: #2a3550 !important;
      color: white !important;
      letter-spacing: 0.02px;
      border: none !important;

      img {
        // margin-left: 30px !important;
        width: 30px;
        margin-bottom: -3px;
      }

      &:hover {
        background-color: white !important;
        color: #2a3550 !important;
        // border-color: #2a3550 !important;

        img {
          filter: invert(1);
        }
      }

      &:focus {
        background-color: #2a3550 !important;
        color: white !important;
        // border-color: #2a3550 !important;
      }
    }

    .controllerIconContainer {
      @include flex(center, center);
      width: 50%;

      img {
        width: 30px;
        cursor: pointer;
      }

      .soundControl {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      .autoplayControl {
        display: flex;
        align-items: center;
        gap: 2px;
        margin-left: 22px;
      }

      .label {
        font-size: 14px;
        color: white;
      }
    }

    .columnsControlPanel {
      width: 100%;
      background-color: #2a3550;
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px;

      .columnsSectionContainer {
        color: white;
        text-transform: capitalize;
        font-size: 14px;
        @include flex(flex-start, unset, column);
        padding-bottom: 15px;
        border-bottom: 1px solid white;
      }

      .columnsControlPanelItem {
        width: 100%;
        padding: 5px 0;
        @include flex(center, space-between);
        cursor: pointer;

        span {
          width: 80px;
          @include flex(center, space-between);
        }

        img {
          width: 30px;
        }
      }
    }

    .applyButton {
      text-transform: uppercase;
      border: 1px solid white;
      border-radius: 3px !important;
      background-color: transparent !important;
      color: white !important;
      float: right;
      margin: 0;

      &:hover {
        background-color: white !important;
        color: #2a3550 !important;
        border-color: transparent !important;
      }

      &:focus {
        background-color: #2a3550 !important;
        color: white !important;
        border-color: white !important;
      }
    }
  }
}
