$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newWorkOrderModalContainer {
  width: fit-content !important;
  //   max-width: 100%;
  .ant-modal-content {
    border-radius: 10px;
    // width: calc(650px + 2rem);
    width: fit-content;
    max-width: 95vw;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        svg {
          fill: #323338;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      text-align: left;
      background-color: #f7f8fa;
      width: 100%;
      border-radius: 10px 10px 0 0;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-modal-title {
        color: #323338;
        font-size: 1rem;
        font-weight: 550;
      }
    }

    .ant-modal-body {
      height: fit-content;
      width: 100%;
      margin: 0;
      padding: 1rem;
      max-height: 80vh;
      overflow-y: auto;

      .small-warning-info-card {
        margin-top: 15px;
        color: #fff;
      }

      .openInventoryLink {
        width: 100%;
        padding: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .infoSection {
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        gap: 1rem;
        width: 100%;
        // flex-direction: row;
        flex-direction: column;

        .ant-collapse-header {
          background-color: #fe4c4a;
          .ant-collapse-expand-icon {
            color: white;
          }
          .ant-collapse-header-text {
            color: white;
          }
        }

        .infoPart {
          display: flex;
          gap: 1rem;
        }
      }

      .truckSelectSection {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 48%;
        min-width: 300px;
        flex-direction: column;

        .ant-form-item {
          margin-bottom: 1rem;
          margin-left: 1rem;
        }

        .truckMileage {
          margin-left: 1rem;
        }
      }

      .teams-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
      }

      .mainContainerUnSelected {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: row;
        margin-top: 1rem;
        min-width: 600px;
      }

      .mainContainerSelected {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        margin-top: 1rem;
        flex-wrap: nowrap;
        column-gap: 2rem;
        width: 850px;
        max-width: 90vw;
      }

      .truckWarningSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;
        width: 48%;
        height: 100px;

        .warningInfo {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 0.3rem;
        }
      }

      .inventorySelection {
        width: 48%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .ant-form-item {
          margin: 0;
        }
      }

      .buttonSelected {
        height: "fit-content";
        width: 100px;
        max-width: 150px;
        color: white;
        background-color: #f34213;
        border-radius: 5px;
      }

      .buttonUnSelected {
        height: "fit-content";
        width: 100px;
        max-width: 150px;
        color: #323338;
        background-color: #f5f5f7;
        border-radius: 5px;
      }

      .priorityView {
        height: 80%;
        padding-left: 3px;
        padding-right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        color: white;
        font-weight: 550;
        border-radius: 5px !important;
      }

      .ant-form {
        height: fit-content;
        width: 100%;
        min-height: 5rem;

        .labeledInputLabel {
          font-size: 0.9rem;
        }

        .workOrderTableContainer {
          height: fit-content;
          max-height: 250px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto;
          margin-top: 2rem;

          .ant-spin-nested-loading {
            width: 100%;
            padding-top: 0.5rem;
            padding-bottom: 1rem;
            margin: 0;
            .ant-spin-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: flex-start;

              .ant-table-wrapper {
                width: 100%;
                min-height: 300px;

                .ant-table {
                  width: 100%;
                  .ant-table-thead {
                    display: none;
                  }
                }
              }
            }
          }

          .ant-alert {
            border-radius: 5px;
            width: 80%;
          }
        }

        .workOrderTableContainerVoid {
          height: fit-content;
          width: 100%;
          display: hidden;
          // justify-content: center;
          // align-items: flex-start;
          // overflow-y: auto;
          // margin-top: 0;

          .ant-spin-nested-loading {
            margin-top: 1rem;
            padding: 0;
          }
        }
      }

      .workOrderAssignContainer {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        row-gap: 1rem;
        column-gap: 1rem;
        padding: 1rem;

        .ant-picker {
          width: 100%;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding-left: 1rem;
      padding-right: 1rem;
      border: none;

      .workOrderFooterText {
        color: #323338;
        font-size: 0.8rem;
        font-weight: 450;
      }
    }
  }
}

@media (max-width: 926px) {
  .newWorkOrderModalContainer {
    .ant-modal-content {
      .mainContainerSelected {
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 850px !important;
        flex-direction: column !important;
        margin-top: 1rem;
        gap: 1rem !important;

        .truckSelectSection {
          width: 65% !important;
        }

        .inventorySelection {
          width: 65% !important;
          .ant-form-item {
            margin-left: 1rem;
          }
        }
      }
      .truckWarningSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;
        width: 100% !important;
        height: 100px;

        .warningInfo {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 0.3rem;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .newWorkOrderModalContainer {
    max-height: 98vh;
    .ant-modal-content {
      .ant-modal-body {
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .mainContainerSelected {
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 80vw !important;
        flex-direction: column !important;
        margin-top: 1rem;
        gap: 1rem !important;

        .truckSelectSection {
          width: 80vw !important;
        }

        .inventorySelection {
          width: 80vw !important;
          .ant-form-item {
            margin-left: 1rem;
          }
        }
      }

      .truckWarningSection {
        width: 100% !important;
      }

      .workOrderAssignContainer {
        margin-top: 1rem !important;
      }
    }
  }
}

.newWorkOrderModalContainerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .mainContainerUnSelected {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-overflow-item .ant-select-selection-item {
              background-color: #323338;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
      }
      .infoSection span {
        color: #fff;
      }

      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;

          .ant-select-selection-overflow-item .ant-select-selection-item {
            background-color: #323338;
            .ant-select-selection-item-remove svg {
              fill: #fff;
            }
          }
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }

      .workOrderAssignContainer,
      .mainContainerSelected {
        .truckMileage {
          color: #fff;
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
        .ant-picker {
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        .ant-picker-disabled {
          .ant-picker-input input {
            color: #acacca !important;
          }
        }
      }
      .workOrderTableContainer {
        .ag-grid-container {
          .ag-header {
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #acacca;
            }
          }
        }
      }
      .truckWarningSection {
        .warningInfo {
          span,
          strong {
            color: #fff;
          }
        }
      }
      .formTextEditorContainer {
        .tox {
          border: none;
          .tox-menubar {
            background-color: $cardModalHeaderDark;
            border-radius: 5px 5px 0px 0px;
            .tox-mbtn__select-label {
              color: #fff;
            }
          }
          .tox-mbtn--select:hover {
            background-color: $cardModalBodyDark;
          }
          .tox-toolbar-overlord {
            background-color: $cardModalHeaderDark !important;
            .tox-toolbar__primary {
              background-color: $cardModalHeaderDark !important;
            }
            .tox-toolbar__group {
              background-color: $cardModalHeaderDark !important;
              border: none;
              .tox-split-button:hover {
                border: none;
              }
              .tox-tbtn {
                svg {
                  fill: #fff;
                }
                &:focus {
                  background-color: $cardModalBodyDark;
                }
                &:hover {
                  background-color: $cardModalBodyDark;
                }
              }
              .tox-tbtn__select-label {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}
