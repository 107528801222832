.chainedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .chainedButton {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    font-weight: 400;
    height: 26px;
    border: none;
    &:hover {
      border: none;
    }
  }
  .chainedButtonActive {
    background: #1264a3;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .chainedButtonNotActive {
    background: #fcd3d3;
    color: #000;
    cursor: not-allowed;
    &:hover {
      color: #000;
    }
  }
}
