@import "../../config.scss";

$headerDarkColor: #12131b;
$inputDarkColor: #2a2b3a;
$mainDarkBackgroundColor: #20212d;

.title-header-comp {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  animation: headerAnim 0.5s ease-in-out;
  background: var(--secondary-background-color) !important;
  background-color: red;
  width: 100% !important;
  @media (max-width: 459px) {
    height: 64px;
  }
}

.title-header-comp-dark {
  background-color: $mainDarkBackgroundColor !important;

  .right-title-header-comp {
    .input-section {
      .containerModal {
        .ant-badge-count {
          box-shadow: 0 0 0 1px $mainDarkBackgroundColor;
        }

        .videoTutorialDiv .ant-btn {
          background-color: #1264a3 !important;
          border: none;
        }
      }

      .search-Div {
        .ant-select {
          .ant-select-selector {
            background-color: $headerDarkColor !important;
            color: #f2f2f2;

            .ant-select-selection-search-input {
              color: #f2f2f2;
            }

            .ant-select-selection-placeholder {
              color: #acacca;
              z-index: 2;
            }
          }

          .ant-select-arrow svg {
            fill: #f2f2f2;
          }

          .ant-select-clear {
            background-color: $headerDarkColor;
          }
        }

        .searchDivider {
          background-color: $inputDarkColor;
        }

        .anticon svg {
          fill: #f2f2f2;
        }
      }
    }
  }

  .dropdownLogo {
    margin-left: 10px;
    width: 60px;
    height: 25px;
    filter: brightness(10);
  }

  .companyOptionStyle {
    padding: 0px !important;
    width: 300px;
    padding-bottom: 3px;
    background-color: transparent;
  }

  .no_logged_tasks {
    padding: 20px 60px 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h2 {
      color: #1f2a44;
      font-weight: 600;
      font-size: 16px;
      margin-top: 0.5rem;
    }

    h5 {
      margin-bottom: 0.7rem;
      font-size: 1rem;
    }

    .mondayButtonContainer {
      .go-tasks-btn {
        background: #1264a3;
        border-radius: 5px;

        .mondayButtonDivider {
          background: #0f5c97;
        }

        &:hover {
          color: #fff;
          filter: brightness(1.3);
          transition: 0.3s;
        }

        &:focus {
          color: #fff;
        }
      }
    }

    .optionClassName {
      display: flex;
      width: 300px;
      height: 32px;
      align-items: center;
      border-radius: 5px;

      .dropDownLabel {
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        line-height: 19px;
      }
    }
  }

  .optionClassName {
    display: flex;
    width: 300px;
    height: 32px;
    align-items: center;
    border-radius: 5px;

    .dropDownLabel {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      line-height: 19px;
      margin-left: 10px;
    }
  }

  .videoTutorialDiv {
    position: relative;
    top: 4px;
  }

  .logout-warning-modal {
    .logout-modal-body {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;

      span {
        width: 35px;
        height: 35px;
      }

      p {
        margin: 0.9rem 0;
        font-size: 15px;
      }

      .buttons {
        display: flex;

        .mondayButtonContainer {
          .mondayButtonText {
            font-size: 14px;
          }

          .mondayButtonDivider {
            height: 100%;
          }
        }

        .decline-logout {
          background-color: #fe4c4a;
          margin-right: 10px;

          .mondayButtonDivider {
            background: #eb4345 !important;
          }
        }
      }
    }
  }

  @media (max-width: 460px) {
    .ant-drawer-content-wrapper {
      width: 380px !important;
    }
  }

  @media screen and (min-device-width: 1000px) and (max-device-width: 1181px) and (orientation: landscape) {
    .title-header-comp
      .right-title-header-comp
      .search-comp
      .ant-select-selector {
      width: 200px;
    }

    // .dynamicBasePage .filterContainers {
    //   // flex-wrap: wrap;
    //   width: 100%;
    //   justify-content: flex-start !important;
    //   display: flex;
    //   padding-top: 3px;
    //   min-height: 85px !important;
    //   height: 85px !important;
    //   overflow: auto !important;
    // }
    .mainFleet {
      .tabContainer .titleComp {
        top: 60px !important;
      }
    }

    span.titleCompBasePage {
      top: 20px !important;
      // left: 260px !important;
      // font: normal normal bold 18px Open Sans !important;
    }

    .scheduleModal .schedule-main-row .step-0 .top-part {
      display: block;
    }

    .scheduleModal .schedule-main-row .step-0 .selection-form {
      display: flex;
      justify-content: inherit;
      gap: 20px;
    }

    .title-header-comp .right-title-header-comp .input-section,
    .title-header-comp .right-title-header-comp {
      padding-right: 10px;
    }

    .projectModalContainer .projectStep {
      margin-top: 0px !important;
    }

    .scheduleModal .schedule-main-row .step-0 .top-part {
      display: block;
    }

    .scheduleModal .schedule-main-row .step-0 .selection-form {
      display: flex;
      justify-content: inherit;
      gap: 20px;
    }

    .requestFormModal .bodyContent {
      display: grid;
    }

    .inputFieldsContainer {
      width: 1000px !important;
    }

    .requestFormModal .customComments {
      width: 420px;
    }

    .ant-modal-wrap.normalSizedModal.requestFormModal.ant-modal-centered {
      .gradientDivider {
        margin: 0px !important;
      }
    }
  }

  @media screen and (min-device-width: 1000px) and (max-device-width: 1024px) and (orientation: landscape) {
    .title-header-comp .right-title-header-comp .input-section,
    .title-header-comp .right-title-header-comp {
      padding-right: 10px !important;
    }

    .overViewStepContainer .mainGrid {
      column-gap: 30px;
      margin-top: -75px;
    }

    .projectModalContainer .projectStep {
      margin-top: 0px !important;
    }

    .scheduleModal .schedule-main-row .step-0 .top-part {
      display: block;
    }

    .scheduleModal .schedule-main-row .step-0 .selection-form {
      display: flex;
      justify-content: inherit;
      gap: 20px;
    }

    .requestFormModal .bodyContent {
      display: grid;
    }

    .inputFieldsContainer {
      width: 900px !important;
    }

    .requestFormModal .customComments {
      width: 420px;
    }

    .ant-modal-wrap.normalSizedModal.requestFormModal.ant-modal-centered {
      .gradientDivider {
        margin: 0px !important;
      }
    }
  }

  @media (max-width: 1728px) {
    .title-header-comp {
      height: 80px !important;
    }

    .input-section {
      margin-right: 10px;
    }

    .optionClassNamee {
      width: 60px !important;
    }

    span.videoBtnText {
      display: none !important;
    }
  }

  @media (max-width: 1520px) {
    .companyOptionStyle {
      width: 80px !important;
    }

    .title-header-comp
      .right-title-header-comp
      .search-comp
      .ant-select-selector {
      width: 220px;
    }
  }

  @media (max-width: 1444px) {
    .ant-modal.modalCustome {
      min-width: 700px;
    }

    .modalCustome::before {
      display: none;
    }

    .modalCustome > .ant-modal-content > .ant-modal-body {
      // width: fit-content;
      margin-top: 50px;
    }

    .title-header-comp .right-title-header-comp .companyComponent .ant-select {
      width: 80px;
    }

    span.ant-select-arrow {
      display: none;
    }

    .title-header-comp .right-title-header-comp .companyComponent {
      // margin-right: -195px !important;
      .dropDownLabel {
        display: none;
      }

      .optionClassName {
        width: 80px !important;
      }

      .ant-select.ant-select-single.ant-select-show-arrow {
        // width: 100px;
        .ant-select-selector {
          width: 80px;
          min-width: 80px;
        }
      }
    }
  }

  @media (max-width: 1225px) {
    .title-header-comp {
      .right-title-header-comp {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
      }

      .right-title-header-comp .input-section,
      .right-title-header-comp {
        justify-content: flex-end;
      }
    }

    // .dynamicBasePage .titleCompBasePage {
    //   top: 85px !important;
    // }
    .dynamicBasePage .filterContainers {
      // flex-wrap: wrap;
      justify-content: flex-start !important;
      // padding-top: 3px !important;
      overflow: auto !important;
    }
  }

  @media (max-width: 939px) {
    .title-header-comp
      .right-title-header-comp
      .search-comp
      .ant-select-selector {
      width: 200px;
    }

    // .labeledIconContainer {
    //   display: contents;
    // }
    .ant-popover.shortcutPopover.ant-popover-placement-right {
      width: 540px;
    }

    .shortcutContentContainer {
      text-align: left;
      grid-template-columns: 1fr;
    }

    .title-header-comp {
      height: 80px;
    }

    // .title-header-comp .right-title-header-comp {
    //   justify-content: flex-end;
    //   align-items: center;
    //   height: 80px;
    //   gap: 5px;
    //   padding-top: 3px;
    //   padding-bottom: 3px;
    // }
    // .dynamicBasePage .titleCompBasePage {
    //   top: 80px !important;
    //   left: 260px !important;
    // }
    .tabContainer {
      .dynamicBasePage {
        margin-top: 0px !important;
      }
    }

    .mainFleet {
      .dynamicBasePage {
        margin-top: 0px !important;
      }
    }
  }

  @media (max-width: 921px) {
    .title-header-comp {
      .right-title-header-comp {
        .input-section {
          .containerModal .videoTutorialDiv {
            .optionClassNamee {
              width: 60px;
            }

            span.videoBtnText {
              display: none !important;
            }
          }
        }

        .searchFilter-select {
          width: 120px;
        }

        .search-comp {
          .ant-select-selector {
            width: 150px;
          }
        }
      }
    }
  }

  // @media (min-width: 2000px) {
  //   .title-header-comp {
  //     height: 80px !important;
  //   }
  //   .input-section {
  //     margin-right: 10px;
  //   }
  //       .optionClassNamee {
  //         width: 200px !important;
  //       }
  //       span.videoBtnText {
  //         display: none;
  //       }
  // }

  @media only screen and (max-width: 821px) {
    .scheduleModal .schedule-main-row .step-0 .top-part {
      display: block;
    }

    .scheduleModal .schedule-main-row .step-0 .selection-form {
      display: flex;
      justify-content: inherit;
      gap: 20px;
    }

    .requestFormModal .bodyContent {
      display: grid;
    }

    .inputFieldsContainer {
      width: 700px !important;
    }

    .requestFormModal .otherDetailsContainer {
      display: grid;
    }

    .requestFormModal .customComments {
      width: 700px;
    }

    .ant-modal-wrap.normalSizedModal.requestFormModal.ant-modal-centered {
      .gradientDivider {
        margin: 0px !important;
      }
    }

    .documentationPageCategorySelector .categoryButtonsContainer {
      flex-wrap: wrap;
      gap: 10px;
    }

    .mainFleet {
      .FilterModalMask .FilterModalComponent {
        width: 820px;
        gap: 20px;
      }

      .dynamicBasePage .filterContainers .filterContainerLeft .searchBasePage {
        width: 180px;
      }
    }

    .labeledInputContainer.formTextEditorContainer {
      width: 100% !important;
    }

    .NotificationPopOver {
      width: 818px !important;
    }

    .childrenContainer.billingAndShippingAddressesContainer
      img.streetViewIcon.shippingAddIcon {
      right: 30px !important;
    }

    .normalSizedModal .ant-modal {
      min-width: 100% !important;
    }

    .reportsCategoriesView .categoryCreatorSection {
      flex-wrap: wrap !important;
    }

    .templateModalContainer {
      width: 820px !important;
    }

    .templateModalContainer .templateModalBody .templateModalBodyLeft {
      width: 100% !important;
    }

    .templateModalContainer .templateModalBody {
      display: block;
    }

    .templateModalContainer .templateModalBody .templateModalBodyRight {
      width: 100% !important;
    }

    .sovSettingContainer .schedule-of-values-templates-view {
      display: block !important;

      .sov-service-list-container {
        margin-bottom: 10px;
      }

      .service-menu-tabs-container {
        margin-top: 10px;
        width: 100%;
      }
    }

    .card-left-side.col-sm-12.col-lg-3 {
      height: 450px !important;
    }

    .title-header-comp
      .right-title-header-comp
      .companyComponent
      .ant-select.ant-select-single.ant-select-show-arrow {
      min-width: 100px;
    }

    .ant-modal.modalCustome {
      min-width: 670px;
    }
  }

  @media only screen and (max-width: 570px) {
    .title-header-comp {
      height: 90px !important;
      padding: 10px 0;

      .right-title-header-comp {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .input-section {
          width: 100%;
          gap: 10px;

          .search-Div {
            width: 150px;
          }

          .companyComponent
            .ant-select.ant-select-single.ant-select-show-arrow {
            min-width: 80px;
          }
        }
      }
    }

    .dynamicBasePage .titleCompBasePage {
      top: 40px !important;
      left: 20px !important;
    }

    .NotificationPopOver {
      width: 770px;
    }

    .scheduleModal .schedule-main-row .step-0 .top-part {
      display: block;
    }

    .scheduleModal .schedule-main-row .step-0 .selection-form {
      display: flex;
      justify-content: inherit;
      gap: 20px;
    }

    .requestFormModal .bodyContent {
      display: grid;
    }

    .inputFieldsContainer {
      width: 700px !important;
    }

    .requestFormModal .otherDetailsContainer {
      display: grid;
    }

    .requestFormModal .customComments {
      width: 700px;
    }

    .ant-modal-wrap.normalSizedModal.requestFormModal.ant-modal-centered {
      .gradientDivider {
        margin: 0px !important;
      }
    }

    .NotificationPopOver {
      width: 767px !important;
    }

    .dashboardPreferencesMainDiv .dashboardPreferencesHeader {
      font-size: 25px !important;
    }

    .dashboardPreferencesMainDiv .dashboardPreferencesMainContainer .tittle {
      font-size: 22px !important;
    }

    .templateModalContainer .templateModalBody {
      display: grid !important;
    }

    .templateModalContainer {
      width: 768px !important;
    }
  }

  @media (max-width: 391px) {
    .subLeadsPageContainer {
      margin-top: 0 !important;
    }

    .dynamicBasePage .titleCompBasePage {
      top: 40px !important;
      font-size: medium !important;
    }

    .title-header-comp .right-title-header-comp .input-section {
      .companyComponent {
        left: 65px;
      }

      .containerModal {
        left: 150px;
      }
    }

    .title-header-comp .right-title-header-comp .input-section,
    .title-header-comp .right-title-header-comp {
      padding-right: 5px;
    }

    .dynamicBasePage .filterContainers {
      overflow: auto !important;
      // height: 100px !important;
    }
  }

  @media only screen and (max-width: 450px) {
    .title-header-comp {
      width: calc(100% - 60px);
    }

    .containerModal {
      padding: 0px;
      width: 80px;
    }

    button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
      display: none;
    }

    .modalCustome .modal1div:first-child {
      width: 75px !important;
    }

    span.videoBtnText {
      display: none !important;
    }

    p.tutorialModalText {
      display: none;
    }

    p.newVideosCategoryText {
      display: none;
    }

    span.videoMsg {
      display: none;
    }

    span.videoCatNameComp {
      max-width: 75px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .modalCustome .modal1div:nth-of-type(1) .ant-collapse {
      display: none !important;
    }

    .newVideosCategoryInfoSmallcont {
      max-width: 180px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p.subCategoryInfoText {
      color: #000000d9;
      max-width: 80px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // -------------------------- Video Tutorials Style -------------------------------
  .modalCustome {
    min-width: 1000px;
    height: 422px !important;
    position: absolute !important;
    top: 80px !important;
    left: 15vw;
    right: 15vw;

    > .ant-modal-content {
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;

      > .ant-modal-body {
        display: flex;
        padding: 10px;

        > .modal1div {
          width: 100%;
          display: flex;
          padding-right: 10px;
          flex-direction: column;
          max-width: 50%;
          padding-left: 10px;
          overflow-y: auto;

          > collapse > collapse {
            width: 100%;
            display: flex;
            padding-right: 10px;
            flex-direction: column;
            max-width: 50%;
            padding-left: 10px;
            overflow-y: auto;
          }

          > .ant-collapse {
            border: none;
          }

          > .span:nth-of-type(1) {
            .span:nth-of-type(1) {
              .span:nth-of-type(1) {
                > .ant-input-prefix {
                  > .span:nth-of-type(1) {
                    > svg {
                      fill: #999;
                      font-size: 20px;
                    }
                  }
                }
              }

              > .ant-input-group-addon {
                > button {
                  border-color: #1264a3 !important;
                  background: #1264a3 !important;
                }
              }
            }
          }

          + div {
            margin: 0 10px;

            > hr {
              margin-top: 10px;
              height: 93%;
              border: 1px solid #33333396;
            }
          }

          &::-webkit-scrollbar,
          > div::-webkit-scrollbar {
            width: 0px;
          }
        }
      }
    }

    &::before {
      content: "";
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
      width: 15px;
      height: 15px;
      background-color: #fff;
      display: block;
      position: absolute;
      z-index: 9999999;
      right: 385px;
      top: -15px;
    }

    .customSearchNew {
      .ant-input-affix-wrapper {
        border: none;
        border-radius: 5px !important;
        background-color: #f5f5f7;

        input {
          background-color: #f5f5f7;
        }
      }

      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button:not(.ant-btn-primary) {
        display: none;
      }

      button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
        display: none;
      }
    }
  }

  .modalCustomeDark {
    > .ant-modal-content > .ant-modal-body > .modal1div + div > hr {
      border-color: #f2f2f2;
    }

    .ant-modal-content {
      background-color: $inputDarkColor;
    }
  }

  .newVideo {
    &SmallButton {
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      padding: 2px;
      font-size: 13px;
      width: 36px;
      background: #f6862c 0% 0% no-repeat padding-box;
      border-radius: 2px;
    }

    &sCategory {
      width: 100%;
      display: flex;
      margin: 6px 0;
      cursor: pointer;
      border-radius: 5px;

      &Info {
        min-height: 50px;
        flex: 1 1;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        align-items: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 100%;

        &Smallcont {
          > h4 {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 500;
          }

          + div:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;

            > .newVideoSmallButton {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        > span {
          margin: 0 10px;
          padding: 0;
        }
      }
    }
  }

  .imagediv {
    width: 50px;
    height: 50px;
    background-color: #a87878;
    background-size: cover;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative !important;
    overflow: hidden;

    > video {
      width: 100%;
      max-width: 50px !important;
      height: 50px;
      transform: translate(25px, 0px) scale(2.1);
      -webkit-transform: translate(25px, 0px) scale(2.1);
      -moz-transform: translate(25px, 0px) scale(2.1);
      -ms-transform: translate(25px, 0px) scale(2.1);
      -o-transform: translate(25px, 0px) scale(2.1);
    }
  }

  .playiconImg {
    background: transparent
      url("https://icon-library.com/images/play-icon-transparent-background/play-icon-transparent-background-22.jpg")
      0% 0% no-repeat padding-box;
    width: 50px;
    background-size: 50px;
    height: 50px;
    display: block;
    z-index: 99999999999999;
    position: absolute;
  }

  .video {
    &Length {
      position: relative;
      padding-left: 4px;
    }

    &Msg {
      background: transparent
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAHYAAAB2AH6XKZyAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAA8tJREFUeJzt20mIHGUYxvHfTKJOxxHFICoqxoAKehGEieIaJQYxroiSU265iAcFI4gHPQjeXA6KIAhRcYGooKIIRoQIiRtqJF5CjCYMCkJinGTMbO3hq8ZJZ3qtt7p6lj98h6aqvvd5n+5vr2aJxc1A3ecKrsNFOKX3cgplEgewA//OdcO9+BPVBV7+wN31yd+YOVS2uF6VSVw/24Av+kBUr8vnpD5gQGoTp1pcHEdlAEMYL1lMWVQGy1ZQNst7GKuKnVLb+wG/4nB27SysxlW4FWucPEQXxpBiO5vjeB6rOtB0CV7Ini1S21DRBrydJdMtq/HOfDRgEg/nSLyezZiYLwYcxtrA5Gusxd/9bsA0NhSQfI31mArUG27Ao4Wl/j+PBeoNNeDLJqKX412M4XVcnM8D2/vNgBlc20TwbXX3j+MZDHdpwEgWs28M2NZC8AMNnhuVevhlXZjwfj8ZsK5LA2rlW9zQoQHr+8WAQ1p/g60MqJUPtT9jXJbFzmVAxGJohzT8RbABe/Aszmhx73QWOxcRBuwOqGM2FTyOX6T+oZnGn/MGizDgQEAdc3EBXsGnWNHgnt/yBokwYCygjmasw+0Nrv2Tt/IIA4reU5jC3gbXcm/jRRiwMqCORuzDXfixwfWz8waIMODSgDrqOYqncSU+aXLfZXkDRfx8RwLqqFHFG9giHWC0Yk1E0LwToWmc1yJGOxOhXbimA93nZ7FLnwgNYmOO5w9ik5T8zg6e2yimCYesBQ5qPFbD/XM8M4YnpYlPp1Twe4Du0P2ALU0Er5JOn6rSMnarNNHplieCNIcacKhFUiOZ8KtzJA4XSvuOfWdAFd9p3hTyUsHXgXoL2RV+S1DnVMegdM4QqbWwc4GPcWZg8sNidoB6ZkAVPwmYqeFyacldhMbCj8YmpCXtuV0kvlLaGKmNHvPSgFo5gpdwk+YruNNwM17Onila11AZL0gck7a99jn5ePwKxY4i9Sy9ITIozcwWKzODUkfVztJzoTGKidqE5c0ylZTECTkP4xvF97r9UnbhdE58EWkFHsF90rvCs3eLhsW8O3xUanJlMCVt4W/Dczrs+D+Q3/X39HaIa4tevSf4orQpcqxH8cLp9hcwhYdK0Ns2RR5qjEn7dh8VGCM3RRkwijvxfUH1h1GEAbtxh+IOTUOJ7gQ/k/6IMC+SJ9aAV6Vv/khgnX1Ds1FgBk+VpqxHNDJgHA+WqCs3eTrBv3APvgrSUgrdGrBHau/746SUQzed4Hbpz5X7Y6X0N1ulNv+ahfeP0rY4B7eULWKJJeL5D1wa1Y+T7y7LAAAAAElFTkSuQmCC)
        0% 0% no-repeat padding-box;
      width: 100%;
      height: 100%;
      max-width: 40px;
      background-size: 27px;
      background-position: center;
      min-height: 50px;
      min-width: 50px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }

    &CatStyle {
      background-color: #1264a3;
      border-radius: 5px;
      justify-content: flex-start;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      color: #fff;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;
      cursor: pointer;

      &:hover {
        background-color: #f8f8fa;
        color: #1264a3;
      }

      &:hover > img,
      &:hover > div > span {
        filter: inherit !important;
        -webkit-filter: inherit !important;
      }

      > img {
        filter: invert(1);
        max-width: 30px;
        -webkit-filter: invert(1);
      }

      &Actived {
        background-color: #f8f8fa;
        color: #1264a3;

        > img,
        > div > span {
          filter: inherit !important;
          -webkit-filter: inherit !important;
        }
      }
    }

    &Modalll {
      overflow: hidden !important;
      height: 100%;
    }
  }

  .collapse {
    &:not(.show) {
      display: block !important;
    }

    &sss {
      background-color: transparent !important;
      border: none !important;

      > .ant-collapse {
        > .ant-collapse-item {
          margin-bottom: 8px;
          border-bottom: none;
        }
      }

      > .ant-collapse-item {
        border: none !important;
        margin: 10px 0 !important;
        user-select: none;

        > .ant-collapse-header {
          padding: 0 !important;

          > .newVideosCategory {
            margin: 0 !important;
            border-radius: 5px !important;
            -webkit-border-radius: 8px !important;
            -moz-border-radius: 8px !important;
            -ms-border-radius: 8px !important;
            -o-border-radius: 8px !important;

            > .newVideosCategoryInfo {
              &:hover {
                border: none;
              }

              > div {
                > .videoMsg {
                  top: -23px;
                }
              }
            }
          }

          + .ant-collapse-content {
            > .ant-collapse-content-box {
              padding: 0;
            }

            &-active {
              border-top: none;
            }
          }

          > div:first-child {
            display: none !important;
          }
        }
      }
    }
  }

  .activeVidd {
    background-color: #f7f7f7 !important;
    border: 2px solid #1264a3;
  }

  .inactiveVidd {
    background-color: #f7f7f7 !important;
    border: transparent;
    border: 2px solid transparent;
  }

  .activeCat {
    border: 2px solid transparent;

    &:hover {
      border: 2px solid transparent !important;
    }

    &:hover div img {
      filter: inherit;
      -webkit-filter: inherit;
    }

    > div:nth-of-type(1) {
      background-color: #f7f7f7;

      > img {
        filter: invert;
        -webkit-filter: invert;
      }

      > div:nth-of-type(1) {
        > span {
          filter: none !important;
          -webkit-filter: none !important;
        }
      }

      > p {
        color: #333;
      }
    }
  }

  .inactiveCat {
    &:hover {
      border: 2px solid transparent !important;

      > div:nth-of-type(1) {
        > img {
          filter: invert;
          -webkit-filter: invert;
        }
      }
    }

    border: 2px solid transparent;
  }

  .newVideosCategory:hover {
    background-color: #f7f7f7 !important;
    border: 2px solid #1264a3;
  }

  .modalCustome > .ant-modal-content > .ant-modal-body {
    max-height: 50vh;
  }

  .containerOpenModal {
    max-height: 94%;
    margin-left: 18px;
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      outline: none;
      border: none;
      background: #1264a3;
    }

    > div::-webkit-scrollbar {
      width: 0px !important;
    }
  }

  .playerModal {
    max-width: 90vw !important;
    max-height: 90vh !important;
    width: unset !important;
    top: 50px !important;

    .ant-modal-body {
      display: flex;
      flex-direction: row;
      height: 80vh !important;
      padding-bottom: 0 !important;
      padding-top: 0px !important;

      .reactPlayerDiv {
        border-radius: 10px;
      }

      .containerOpenModal {
        margin-top: unset !important;
        max-height: 100% !important;
      }
    }

    @media (max-width: 990px) {
      .ant-modal-body {
        flex-direction: column !important;

        .containerOpenModal {
          margin-left: unset !important;
          width: 100% !important;

          p.newVideosCategoryText {
            display: unset !important;
          }
        }

        .reactPlayerDiv {
          width: 100% !important;
          height: 500px !important;
        }
      }
    }
  }

  .activesubCat {
    margin: -5px 0;
    border: 2px solid transparent !important;

    &:nth-of-type(1) {
      margin-top: 0px;
    }

    > div:nth-of-type(1) {
      background: #f7f7f7 !important;

      > p {
        color: #1264a3 !important;
      }

      > img,
      > div:nth-of-type(1) > span {
        filter: none !important;
        -webkit-filter: none !important;
        top: -24px;
      }
    }

    border: none !important;
  }

  .inactiveSubCat {
    margin: -5px 0;
    border: 2px solid transparent !important;

    &:nth-of-type(1) {
      margin-top: 0px;
    }

    &:hover {
      border: 2px solid transparent !important;

      > div:nth-of-type(1) {
        > img {
          filter: invert;
          -webkit-filter: invert;
        }
      }
    }

    > div:nth-of-type(1) {
      > img,
      > div:nth-of-type(1) > span {
        top: -24px;
      }
    }

    border: 0px solid transparent;
  }

  .tasknewcontmodal {
    z-index: 2 !important;
  }

  .totalVideoTime {
    background-color: #f7f7f7 !important;
    position: relative;
    z-index: 10;

    > span:nth-of-type(1) {
      position: absolute;
      bottom: 5px;
      right: 5vw;
      width: 400px;
      z-index: 10;
      text-align: center;
    }
  }

  .collapsesss > .panel > .ant-collapse-header {
    width: 100% !important;

    & > .ant-collapse-header-text {
      width: 100% !important;
    }

    & > .newVideosCategory:hover {
      border: 0px solid transparent !important;
    }
  }

  .customSearchNew {
    > snap.span:nth-of-type(1) {
      > snap.span:nth-of-type(1) {
        > input {
          background-color: #333 !important;
        }
      }

      > snap.span:nth-of-type(2) {
        > button {
          background-color: #000 !important;
        }
      }
    }
  }

  .videoLength,
  .videoCatNameComp {
    font-size: 12px !important;
  }

  .pointVideoLength {
    background: #333;
    width: 5px;
    height: 5px;
    display: inline-block;
    margin: 0 4px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal.modalCustome {
      left: 0px !important;
    }

    .modalCustome > .ant-modal-content > .ant-modal-body {
      margin-top: 25px !important;
    }
  }

  @keyframes headerAnim {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }
}

// -------------------------- End Video Tutorials Style ------------------------------- //
