@use "/src/index.scss" as index;

.emp-sidebar-item {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #323338;
  cursor: pointer;
  transition: 0.2s ease-in;
  background-color: #f4f5f6;
  .mondayButtonRedHover:hover {
    @include index.mondayButton(#fe4c4a, #eb4345);
  }
  &:hover {
    background-color: #f1f5fd;
  }
  &.active {
    background-color: #f1f5fd;
    border: 1px solid #1264a3;
  }

  .status-pin {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
  &.no-match {
    background-color: #dee2eb;
    &:hover {
      background-color: #d2d6dd;
    }
  }
  .emp-data-wrapper {
    margin-bottom: 10px;
  }
  .emp-name {
    font-size: 14px;
    font-weight: 600;
  }
  .ant-form-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .emp-data {
    display: flex;
    align-items: center;
    gap: 10px;
    label {
      font-weight: 600;
    }
    .emp-status {
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
    }
  }
  .approve-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .header-data {
    justify-content: space-between;
  }
}

.emp-sidebar-item-dark {
  background-color: index.$cardDarkHeaderColor;
  &:hover {
    background-color: #41435d;
  }
  &.active {
    background-color: #41435d;
  }
  .emp-data {
    color: #fff;
  }
}

@media (max-width: 1024px) {
  .emp-sidebar-item {
    font-size: 13px;
    .emp-name {
      font-size: 13px;
    }
    .emp-status {
      font-size: 12px;
      text-wrap: nowrap;
      overflow: ellipsis;
    }
  }
}
