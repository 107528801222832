@import "../../../index.scss";
@import '../../../assets/styles/breakpoints.scss';

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.advanced-filters-modal-mobile {
  .ant-modal {
    width: 100% !important;
  }
  .ant-modal-content {
    width: 100% !important;

    .ant-modal-body {
      .body-modal {
        .filter-fields-container {
          overflow: scroll;
          width: 100%;
          .advancedFiltersInputs {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 10px;
          }
        }
      }
    }
  }
}

.advanced-filters-modal {
  .ant-modal {
    // width: 60vw !important;
  }
  .ant-modal-content {
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    // width: 60vw;
    height: fit-content;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-modal-close {
      height: 45px;
      // width: 45px;
      .ant-modal-close-x {
        // width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      background-color: #f5f5f7;
      border: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #323338;
      }
    }
    .ant-modal-footer {
      padding: 0px;
      margin: 0px;
      border-top: 0px;

      .footer-container {
        display: flex;
        height: 72px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        @include mobile{
          flex-direction: column-reverse;
          gap: 10px;
        }
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        padding-top: 10px;

        .filter-button {
          height: 32px;
          margin: 0px 20px;
          @include mondayButton(#1264a3, #0f5c97, #fff);
        }
        .clear-filter-button {
          height: 32px;
          margin: 0px 20px;
          @include mondayButton(#fe4c4a, #eb4345, #fff);
          svg {
            height: 15px;
            fill: white;
          }
        }
      }
    }
    .ant-select-single {
      // width: 200px !important;
    }

    .ant-modal-body {
      // width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .body-modal {
        // max-width: 100vw;
        // width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        overflow-x: hidden;
        overflow-y: auto;

        .filter-fields-container {
          border: 1px solid #1264a3;
          border-radius: 10px;
          padding: 20px;
          width: 100%;
          .advancedFiltersInputs {
            display: flex;
            gap: 20px;
            padding: 10px;
          }

          .ant-select-selection-search-input {
            width: 100%;
          }
        }

        .filter-holder {
          display: flex;
          padding: 10px;
        }

        .fields-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          .filter-text {
            text-align: left;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-weight: 600;
            font-stretch: normal;
            font-size: 18px;
            font-family: "Open Sans";
            letter-spacing: 0px;
            color: #323338;
          }

          .description-container {
            padding: 5px 40px;
          }

          .ant-picker {
            border: 0px solid #f5f5f7;
            border-radius: 5px;
            background-color: #f5f5f7;
            min-width: 200px;

            .ant-picker-suffix {
              svg {
                fill: #cccccc;
              }
            }
          }

          .ant-select-selector {
            border: 1px solid #f5f5f7;
            border-radius: 5px;
            background-color: #f5f5f7;

            .ant-select-arrow {
              svg {
                fill: #cccccc !important;
              }
            }
          }
        }
      }

      .fetch-body-container {
        max-width: 620px;
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px 15px 0 15px;
        border-radius: 10px;

        .fields-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          .filter-text {
            text-align: left;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-weight: 600;
            font-stretch: normal;
            font-size: 18px;
            font-family: "Open Sans";
            letter-spacing: 0px;
            color: #323338;
          }

          .description-container {
            padding: 5px 40px;
          }

          .ant-picker {
            border: 0px solid #f5f5f7;
            border-radius: 5px;
            background-color: #f5f5f7;

            .ant-picker-suffix {
              svg {
                fill: #cccccc;
              }
            }
          }

          .ant-select-selector {
            width: 200px !important;
            border: 1px solid #f5f5f7;
            border-radius: 5px;
            background-color: #f5f5f7;

            .ant-select-arrow {
              background-color: lightseagreen !important;
              svg {
                fill: #cccccc;
              }
            }
          }
        }
      }
    }

    .fetch-filter-button-container {
      width: stretch;
      height: 40px;
      // box-shadow: 0px 1px 4px #00000029;
      margin: 10px 15px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .divider {
        width: 20px;
      }

      .filter-fetch-button {
        width: 100px;
        height: 32px;
        border-radius: 5px;
        background-color: #1264a3;
        border: 1px solid #1264a3;
        color: white;

        &:disabled {
          width: 100px;
          height: 32px;
          border-radius: 5px;
          background-color: #1264a3;
          border: 1px solid #1264a3;
          color: white;
          cursor: not-allowed;
        }
      }

      .filter-fetch-button-unselected {
        width: 100px;
        height: 32px;
        border-radius: 5px;
        color: #1264a3;
        background-color: white;
        border: 1px solid #1264a3;

        &:disabled {
          width: 100px;
          height: 32px;
          border-radius: 5px;
          color: #11568b;
          background-color: rgb(214, 214, 214);
          border: 1px solid #11568b;
          cursor: not-allowed;
        }
      }
    }
  }
}

.advanced-filters-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #f2f2f2;
      }
    }
    .ant-modal-body {
      padding: 20px;
      .fetch-filter-button-container {
        .filter-fetch-buttonUnSelected {
          background-color: $inputModalDark;
        }
      }
      .fetch-body-container {
        .fields-container {
          .filter-text,
          .description-container {
            color: #f2f2f2;
          }
          .ant-picker {
            background-color: $inputModalDark;
            .ant-picker-input {
              background-color: $inputModalDark;
              input {
                &::placeholder {
                  color: #acacca;
                }
              }
            }
            .ant-picker-range-separator .anticon svg {
              fill: #acacca;
            }
            .ant-picker-suffix svg {
              fill: #acacca !important;
            }
          }
          .ant-select {
            .ant-select-selector {
              border: none;
              background-color: $inputModalDark !important;
              color: #f2f2f2;
              .ant-select-selection-search-input {
                background-color: $inputModalDark;
                color: #f2f2f2;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
                z-index: 2;
              }
            }
            .ant-select-arrow svg {
              fill: #f2f2f2;
            }
          }
        }
      }
      .body-modal {
        .fields-container {
          .filter-text,
          .description-container {
            color: #f2f2f2;
          }
        }
        .filter-fields-container {
          border: 1px solid #1264a3;
          border-radius: 10px;
          padding: 20px;
          overflow: scroll;
          width: 100%;
          .advancedFiltersInputs {
            display: flex;
            gap: 20px;
            padding: 10px;
          }
        }
        .filter-holder {
          .right-side-container {
            .ant-select {
              .ant-select-selector {
                border: none;
                background-color: $inputModalDark !important;
                color: #f2f2f2;
                .ant-select-selection-search-input {
                  background-color: $inputModalDark;
                  color: #f2f2f2;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                  z-index: 2;
                }
                .ant-select-selection-overflow {
                  .ant-select-selection-item {
                    background-color: $cardModalBodyDark;
                    .anticon svg {
                      fill: #f2f2f2;
                    }
                  }
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
              }
              .ant-select-arrow svg {
                fill: #f2f2f2;
              }
            }
            .FunctionPicker_function-picker-container__TEe81 {
              .FunctionPicker_function-button__Bzmmi {
                background-color: $inputModalDark;
                border: none;
                span,
                svg {
                  color: #f2f2f2;
                  fill: #f2f2f2;
                }
              }
            }
            .FunctionPicker_function-picker-modal__RGqHI {
              background-color: $cardModalHeaderDark;
              .ant-input-affix-wrapper {
                background-color: $cardModalBodyDark !important;
                .ant-input {
                  background-color: $cardModalBodyDark !important;
                  color: #f2f2f2;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-input-suffix svg {
                  fill: #f2f2f2;
                }
              }
              .FunctionPicker_li__UVUlM {
                color: #f2f2f2;
                &:hover {
                  background-color: $cardModalBodyDark;
                }
              }
            }
            .Addfilter-button_offset-button__4ODgS {
              color: #f2f2f2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .advanced-filters-modal {
    .ant-modal-body {
      .fetch-body-container,
      .body-modal {
        margin: 0px;
        .filter-holder {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
