.header-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 16px;
  align-items: center;

  .header-container-icon {
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background: #f8f8fa;
    width: 35px;
    height: 30px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .search-logs {
    width: 240px;
    margin-right: auto;
  }
}

.header-container-mobile {
  .pdfExcelDropdown {
    .emailBoxContainer {
      div {
        .EmailBoxContainer {
          right: 0;
          transform: translate(90px, 0px);
        }
      }
    }
  }
}

.header-container-dark {
  .ant-input-affix-wrapper {
    background-color: #12131b !important;
    .ant-input-prefix svg {
      fill: #fff;
    }
    .ant-input {
      background-color: #12131b !important;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
  }
}
