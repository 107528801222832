.ant-modal.forecasting-charts {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  .ant-modal-content {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-footer {
    display: flex;
    flex-shrink: 0;
    width: 100% !important;
    background-color: #f5f5f5;
  }

  .ant-modal-body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ant-modal-mask {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
