.changeLicensePlate {
  min-width: 345px;
  .ant-picker {
    width: 100%;
  }
}

.changeLicensePlateDark {
  #activeLicensePlateStartDate {
    color: #fff;
  }
}
