.LongTextBodyEdit {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0px;
  @media screen and (max-width: 600px) {
    width: 99%;
    overflow: auto;
  }
  .LongTextBodyElementContainer {
    max-height: 500px;
    width: 630px;
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
