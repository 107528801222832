.citationModal {
  width: 100%;
  .safetyDirectorSignature {
    .childrenContainer {
      justify-content: center;
      .labeledInputContainer {
        margin-right: 10px;
      }
    }
  }
  .citationModalContent {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .docUploaderAndForm {
      display: flex;
      margin-top: 30px;
      gap: 10px;
      .uploaderSection {
        width: 20%;
      }
      form {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-top: 20px;
        overflow: auto;
        max-height: 75vh;
        .formElements {
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          .labeledInputContainer:has(input[type="text"]) {
            width: 20%;
          }
          .labeledInputContainer:has(input[type="search"]) {
            width: 20%;
          }
          .labeledInputContainer:has(div[type="textEditor"]) {
            width: 100%;
          }
        }
      }
    }
    .formButtons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      background-color: #fff;
      button {
        margin-left: 10px;
        &.emailButton {
          svg path {
            fill: #fff !important;
          }
        }
      }
      div {
        display: flex;
      }
    }
  }

  .citationModalContentDarkMode {
    background-color: #1f212d;
    .formButtons {
      background-color: rgb(31, 33, 45);
    }
    .richTextComp .quill {
      background-color: #12131b;
      color: #fff;
      .ql-formats {
        color: #fff !important;
      }
      p {
        color: #fff !important;
      }
    }
    .quill .ql-toolbar .ql-formats {
      path,
      line,
      polygon {
        stroke: #fff !important;
      }
      .ql-picker-label {
        color: #fff !important;
      }
    }
    .ql-editor.ql-blank::before {
      color: rgba(255, 255, 255, 0.2);
    }
    .anticon.anticon-close-circle svg {
      fill: #fff !important;
    }
    .stepper-section {
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: rgb(31, 33, 45);
        }
        .ant-steps-item-wait {
          background-color: #12131b;
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
    }
    .borderedTextCard .cardTitle {
      background-color: transparent;
      color: #fff;
    }
    :where(
        .css-dev-only-do-not-override-1frn1vs
      ).ant-btn-variant-solid:disabled,
    :where(
        .css-dev-only-do-not-override-1frn1vs
      ).ant-btn-variant-solid.ant-btn-disabled {
      background-color: #acacca;
    }
  }
}
.warningButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  button {
    margin-left: 10px;
  }
}
@media screen and (max-width: 700px) {
  .citationModal {
    .citationModalContent {
      overflow-y: auto;
      padding: 20px;
      gap: 20px;
      .docUploaderAndForm {
        flex-direction: column-reverse;
        gap: 10px;
        width: 100%;
        .uploaderSection {
          width: 100%;
        }

        form {
          width: 100%;
          overflow: hidden;
          max-height: fit-content;
          .formElements {
            .labeledInputContainer {
              width: 100% !important;
            }
          }
        }
      }
    }
    .formButtons {
      justify-content: flex-start;
      flex-wrap: wrap;
      & > .mondayButtonContainer {
        margin-top: 10px;
      }
      div {
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}
