$main-tree-color: #f5f5f7;
$main-tree-color-dark: #12131b;

.tree-collapse-container {
  background-color: $main-tree-color;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  // border: 1px solid red;
  p {
    margin: 0;
  }

  .tree-collapse-body {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    cursor: pointer;
    white-space: nowrap;
    background-color: $main-tree-color;

    .tree-collapse-icon {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  .tree-collapse-items {
    margin-top: 10px;
    background-color: white;
    border-top: 1px solid #dee2eb;
    border-bottom: 1px solid #dee2eb;

    .tree-collapse-items-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .vertical-line {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        width: 1px;
        background-color: #323338;
      }
      .horizontal-line {
        position: absolute;
        top: 20px;
        left: 15px;
        width: 15px;
        border-top: 1px solid black;
      }
      .tree-collapse-items-header-container {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .tree-collapse-items-header-title {
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 5px;
      }
    }
  }
}

.tree-collapse-container-dark {
  background-color: $main-tree-color-dark;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  padding: 10px;

  .tree-collapse-body-dark {
    display: flex;
    gap: 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: $main-tree-color-dark;
    padding: 0 15px;

    .tree-collapse-icon-dark {
      display: flex;
      align-items: center;
      svg {
        fill: #fff;
      }
    }
  }

  .tree-collapse-items {
    margin-top: 10px;
    background-color: #1f212d;

    .tree-collapse-items-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .vertical-line {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        width: 1px;
        background-color: #f8f8fa;
      }
      .horizontal-line {
        position: absolute;
        top: 20px;
        left: 15px;
        width: 15px;
        border-top: 1px solid #fff;
      }
      .tree-collapse-items-header-container {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .tree-collapse-items-header-title {
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 5px;
      }
    }
  }
}
