.ag-footer-stats-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 40px;
  max-width: 1200px;
  padding: 0 5px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .key-stats {
    display: flex;
    gap: 5px;
    white-space: nowrap;
  }
  .key-stats-item {
    display: flex;
    gap: 5px;
    white-space: nowrap;
  }
}
