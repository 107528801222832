.settingsModal {
  .ant-modal {
    top: 30px;
    padding: 0;
    border-radius: 10px;
    .ant-modal-content {
      border-radius: 10px;
    }
    .ant-modal-close {
      .ant-modal-close-x {
        color: white;
        font-size: 1.25rem;
      }
    }

    .ant-modal-header {
      background-color: #1a243b;
      // border-bottom-color: #4e586e;
      border: 0;
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        color: white;
        font-size: 1.25rem;
        letter-spacing: 2px;
      }
    }

    .ant-modal-body {
      padding: 24px 0 0 0;
      border-radius: 0 0 10px 10px;
      background-color: #2a3550;
    }
  }
}
