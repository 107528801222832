$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
$dark-text-color: #fff;

.notifications-drawer {
  .ant-drawer-header {
    background-color: #f7f8fa;
    border-bottom: unset;

    .ant-drawer-close svg {
      fill: #323338;
      height: 17px;
      width: 17px;
    }

    .ant-drawer-title {
      .notifications-drawer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-container {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          padding-right: 10px;

          .drawer-title {
            font: normal normal bold 16px/30px Open Sans;
          }
        }

        .right-container {
          .searchInput {
            margin-left: 10px;
          }
        }
      }
    }

    .header-actions-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .ant-drawer-body {
    padding: 0;
    overflow: hidden;

    .notification-tabs {
      overflow: hidden;
      height: calc(100% - 50px);

      .ant-tabs-nav {
        margin: 0;
        background-color: #f7f8fa;

        .ant-tabs-nav-wrap {
          display: flex;
          justify-content: center;

          .ant-tabs-nav-list {
            gap: 1.5rem;

            .ant-tabs-tab {
              .tab-label {
                color: var(--unnamed-color-1264a3);
                text-align: left;
                font: normal normal 600 16px/24px Open Sans;
                letter-spacing: 0px;
                opacity: 1;
              }
            }

            .ant-tabs-ink-bar-animated {
              background: #1264a3 0 0 no-repeat padding-box;
              height: 5px;
            }

            .ant-tabs-tab-active {
              &::before {
                content: url("../../../../assets/icons/bobBuilder.svg");
                // width: 0px;
                // height: 7px;
                position: absolute;
                left: -36px;
                // top: 0;
                transition: left 0.3s;
              }

              .tab-label {
                color: #1264a3;
              }
            }
          }
        }
      }

      .ant-tabs-content {
        .notifications-body {
          height: calc(100% - 65px);
          display: flex;
          flex-direction: column;

          .notifications-filters {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // gap: 20px;
            margin-left: 15px;
            margin-right: 15px;

            .filters {
              display: flex;
              align-items: center;
              // gap: 10px;
              .filtersMap {
                display: flex;
                align-items: center;
                width: 680px;
                white-space: nowrap;
                overflow: hidden;
                height: 68px;
                @media (max-width: 460px) {
                  width: 100%;
                  height: fit-content;
                }
              }

              .filter-text {
                font: normal normal 600 16px/24px Open Sans;
              }

              .ant-badge {
                top: 0;
                top: 0;
              }

              .filter-item {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                border-radius: 5px;
                padding: 3px 5px;
                cursor: pointer;
                white-space: nowrap;

                .filter-icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .filter-label {
                  margin-right: 5px;
                }
              }
            }

            .mark-read-btn {
              cursor: pointer;
              color: #1264a3;
              font: normal normal 600 14px Open Sans;
              //   font-size: 14px;
            }
          }

          .notifications-modify {
            height: 2.5rem;
            display: flex;
            flex-direction: row !important;
            justify-content: center;
            align-items: center;
            background-color: #f7f8fa;
            overflow: hidden;

            button {
              border: 0;
              font-weight: 600;
              color: #1264a3;
              background-color: transparent;
              transition: color 0.3s ease;

              &:hover {
                color: #1264a3;
              }
            }

            .waitingButton {
              color: #1264a3a6;

              &:hover {
                color: #1264a3a6;
                cursor: default;
              }
            }
          }

          .notifications-container {
            height: calc(100% - 50px);
            overflow: auto;
            overflow-x: hidden;

            .notification-item {
              display: flex;
              align-items: center;
              gap: 10px;
              border-bottom: 1px solid #ebebeb;

              &.moveRight {
                animation: moveRight 0.6s !important;
              }

              &.moveLeft {
                animation: moveLeft 0.6s !important;
              }

              .pinned-icon {
                opacity: 1;
                visibility: visible;
                transition: opacity 1s, visibility 0.01s ease-out;
                font-size: 16px;
              }

              &:hover {
                background-color: #d5ddf4 !important;

                .pinned-icon {
                  opacity: 0;
                  visibility: hidden;
                }

                .notification-checkbox {
                  background-color: #d5ddf4 !important;
                  width: 80px;
                }
              }

              &.pinned-item {
                border-left: 4px solid #f6862c;
              }

              .notification-checkbox {
                width: 0;
                height: 50px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: width 0.3s 0.1s ease-in-out;
                gap: 10px;

                .ant-checkbox-inner {
                  background-color: black;
                  border-color: black;
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: black;
                  border-color: black;
                }
              }

              .notification-checkbox-active-box {
                width: 80px;
              }

              .notification-checkbox-inactive-read {
                background-color: #fff;
              }

              .notification-checkbox-inactive-new {
                background-color: #ffe4ce;
              }

              .notification-checkbox-inactive-not-read {
                background-color: #f4f7ff;
              }

              .notification-icon {
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  width: 100%;

                  path {
                    fill: #323338;
                  }
                }
              }

              .notification-new {
                svg {
                  path {
                    fill: #f6862c;
                  }
                }
              }

              .notification-text {
                width: calc(100% - 60px);
                display: flex;
                align-items: center;
                flex: auto;
                font: normal normal normal 15px/20px Open Sans;

                .NotificationPopOverBodyDetailsReadMore {
                  text-align: left;
                  font: normal normal 600 11px/9px Open Sans;
                  letter-spacing: 0px;
                  color: #323338;
                  opacity: 1;
                  cursor: pointer;
                  margin-left: 10px;
                  overflow: hidden;
                }
              }

              .notification-date {
                text-wrap: nowrap;
              }

              .notification-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .notification-date {
                  text-wrap: nowrap;
                  font: normal normal normal 11px/9px Open Sans;
                  color: #000;
                }

                .notification-delete-icon {
                  display: flex;
                  align-items: center;
                  fill: #fe4c4a;
                  cursor: pointer;

                  &:hover {
                    fill: #eb4345;
                  }
                }

                .more-icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  cursor: pointer;

                  svg {
                    fill: #323338;
                  }

                  &:hover {
                    background-color: rgb(244, 247, 255);
                  }
                }
              }
            }
          }

          /* WebKit Scrollbar Styles (iOS-like) */
          ::-webkit-scrollbar {
            width: 12px;
            /* Width of the scrollbar */
          }

          ::-webkit-scrollbar-thumb {
            background-color: #a0a0a0;
            /* Color of the scrollbar thumb */
            border-radius: 6px;
            /* Rounded corners for the thumb */
            border: 3px solid #ffffff;
            /* Border around the thumb */
          }

          ::-webkit-scrollbar-track {
            background-color: #f0f0f0;
            /* Color of the scrollbar track */
          }

          ::-webkit-scrollbar-corner {
            background-color: #f0f0f0;
            /* Color of the scrollbar corner */
          }

          /* Hide scrollbar buttons (arrows) */
          ::-webkit-scrollbar-button {
            display: none;
          }

          .notifications-skeleton {
            .ant-skeleton-content {
              display: flex;
              align-items: baseline;
              gap: 50px;
            }
          }

          .notifications-skeleton-container {
            .ant-skeleton-content {
              vertical-align: middle;
            }

            .ant-skeleton-title {
              margin-block-start: 0;
            }
          }
        }
      }
    }

    .notifications-footer {
      height: 50px;
      padding: 8px;
      display: flex;
      justify-content: flex-end;
    }

    @media only screen and (max-width: 767px) {
      .notifications-footer {
        justify-content: center;

        .ant-pagination {
          display: flex;
          overflow-y: auto;

          .ant-pagination-item {
            min-width: fit-content;
          }
        }
      }
    }
  }
}

.notifications-drawer-dark {
  .notification-item {
    color: $dark-text-color;

    .notification-checkbox {
      .ant-checkbox-inner {
        background-color: #6d6d6d !important;
        border-color: #6d6d6d !important;
      }
    }
  }

  .notification-icon {
    svg {
      path {
        fill: #fff !important;
      }
    }
  }

  .ant-empty-description {
    color: #fff;
  }

  .ant-drawer-content {
    background-color: $cardModalBodyDark;

    .ant-drawer-header {
      background-color: $cardModalHeaderDark;

      .ant-drawer-header-title {
        .ant-drawer-close svg {
          fill: #fff;
        }

        .ant-drawer-title {
          color: #fff;

          .headerSearch {
            background-color: $cardModalBodyDark;

            .searchInput {
              background-color: $cardModalBodyDark;

              .ant-input-prefix svg {
                fill: #fff;
              }

              .ant-input {
                background-color: $cardModalBodyDark !important;
                color: #fff;

                &::placeholder {
                  color: #acacca;
                }
              }
            }
          }
        }
      }
    }

    .ant-drawer-body {
      .notification-tabs {
        .ant-tabs-nav {
          background-color: $inputModalDark;

          .ant-tabs-tab {
            .ant-tabs-tab-btn span {
              color: #fff;
            }
          }
        }

        .ant-tabs-content .notifications-body {
          .notifications-filters {
            .filters {
              .filter-text {
                color: #fff;
              }

              .filter-item {
                border: none !important;

                .filter-label {
                  color: #fff !important;
                }

                .filter-icon svg {
                  fill: #fff;
                }
              }
            }
          }

          .notification-item {
            border-bottom: 1px solid #acacca;

            &:hover {
              background-color: $cardModalBodyDark !important;

              .notification-checkbox {
                background-color: $cardModalBodyDark !important;
              }
            }

            .notification-actions {
              .notification-date {
                color: #fff !important;
              }

              .more-icon {
                &:hover {
                  background-color: $cardModalBodyDark;
                }

                svg {
                  fill: #fff !important;
                }
              }
            }

            .notification-checkbox {
              background-color: $cardModalBodyDark !important;
            }
          }

          .notifications-modify {
            background-color: $cardModalBodyDark;

            button {
              color: #fff;
            }
          }

          /* Dark Mode WebKit Scrollbar Styles (iOS-like) */
          ::-webkit-scrollbar {
            width: 12px;
            /* Width of the scrollbar */
          }

          ::-webkit-scrollbar-thumb {
            background-color: #555;
            /* Dark color for the scrollbar thumb */
            border-radius: 6px;
            /* Rounded corners for the thumb */
            border: 3px solid #333;
            /* Border around the thumb */
          }

          ::-webkit-scrollbar-track {
            background-color: #222;
            /* Dark color for the scrollbar track */
          }

          ::-webkit-scrollbar-corner {
            background-color: #222;
            /* Dark color for the scrollbar corner */
          }

          /* Hide scrollbar buttons (arrows) */
          ::-webkit-scrollbar-button {
            display: none;
          }
        }
      }

      .notifications-footer {
        .ant-pagination {
          .ant-pagination-prev svg,
          .ant-pagination-next svg {
            fill: #fff;
          }

          .ant-pagination-disabled svg {
            fill: #acacca;
          }

          .ant-pagination-item a {
            color: #fff;
          }

          .ant-pagination-item-active {
            background-color: $cardModalBodyDark;

            a {
              color: #1677ff;
            }
          }
        }
      }
    }
  }
}

//style popover three dots
.notification-more-popover {
  .ant-popover-arrow {
    //   display: none;
    right: -10px !important;
  }

  .ant-popover-content {
    width: 130px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
    overflow: hidden;
    margin-right: -10px;
    padding: 0px;

    .ant-popover-inner-content {
      padding: 5px;
      color: rgba(0, 0, 0, 0.85);

      .notification-popover-content {
        display: flex;
        flex-direction: column;

        .popover-item {
          cursor: pointer;
          text-align: left;
          font: normal normal normal 13px/17px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          opacity: 1;
          padding-left: 7px;
          padding-top: 7px;
          padding-bottom: 7px;

          &:hover {
            background-color: #1264a3;
            color: #fff;
          }
        }
      }
    }
  }

  .ant-popover-inner-content {
    padding: 0;
    color: rgba(0, 0, 0, 0.85);

    .popover-content {
      display: flex;
      flex-direction: column;

      .popover-item {
        cursor: pointer;
        text-align: left;
        font: normal normal normal 13px/17px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        padding-left: 7px;
        padding-top: 7px;
        padding-bottom: 7px;

        &:hover {
          background-color: #1264a3;
          color: #fff;
        }
      }
    }
  }
}

//RESPONSIVITY

// iPhone
@media (max-width: 460px) {
  .notifications-drawer {
    .ant-drawer-content-wrapper {
      width: 100% !important;

      .notification-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            white-space: unset !important;
            padding: 0px 20px;

            .ant-tabs-nav-list {
              gap: 30px !important;

              .ant-tabs-tab-active {
                &::before {
                  left: -28px;
                }

                &:first-child {
                  &::before {
                    left: 0px;
                    right: -30px;
                    transform: scaleX(-1);
                  }
                }
              }

              .ant-tabs-tab {
                margin: 0;
                white-space: nowrap;

                .tab-label {
                  font-size: 11px;
                }
              }
            }
          }
        }

        .badge-notification-count {
          // margin: 20px;
        }

        .notifications-body {
          .notifications-filters {
            gap: 5px !important;
            flex-direction: column;
            .filters {
              flex-direction: column;
            }
          }

          .notifications-container {
            .notification-item {
              .notification-text {
                font: unset !important;
              }
            }

            height: calc(100% - 80px) !important;
          }
        }
      }
    }
  }
}

//iPad
@media (max-width: 1025px) {
  .notifications-drawer {
    .ant-drawer-content-wrapper {
      .notification-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              gap: 0;
            }
          }
        }

        .notifications-body {
          display: flex;
          flex-direction: column;

          .notifications-filters {
            .filters {
              .filter-label {
                display: none;
              }
            }
          }

          .notifications-container {
            .notification-item {
              .notification-icon {
                width: 10%;
              }

              .notification-text {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
}

//iPad width
@media (min-width: 461px) and (max-width: 1025px) {
  .notifications-drawer {
    .ant-drawer-content-wrapper {
      width: 600px !important;
    }
  }
}

//KEYFRAMES
@keyframes removeItem {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes scale-display-reversed2 {
  0% {
    transform: translateX(600px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes readMore {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes readMoreReverse {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-60px);
    opacity: 0;
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(1200px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1200px);
  }
}

@keyframes loadAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
