.toolbox-item {
  gap: 5px;
  height: 32px;
  padding: 5px;
  display: flex;
  cursor: pointer;
  min-width: 32px;
  width: max-content;
  border-radius: 5px;
  align-items: center;
  box-sizing: border-box;
  transition: background-color 0.3s;
  &:hover {
    background-color: #dcdcdc;
  }
}
.toolbox-item.selected {
  background-color: #dcdcdc;
  border: 1px solid #00000050;
}
